import * as React from 'react';
import {Subtract} from "utility-types";
import {FilterConsumer, IFilterContext} from "./FilterContext";


export interface IWithFilterContextProps {
  filterContext: IFilterContext;
}

export const withFilterContext = <P extends IWithFilterContextProps>(Component: React.ComponentType<P>) =>
  class WithFilterContext extends React.PureComponent<Subtract<P, IWithFilterContextProps>> {
    public render() {
      return(
        <FilterConsumer>
          {
            (filterContext: IFilterContext) => <Component
              {...this.props}
              filterContext={filterContext}
            />
          }
        </FilterConsumer>
      )
    }
  };
