import getInstance from '../../tracking/Pulse';

export function trackEngagement(eventLabel: string, targetId: string) {
  const pulse = getInstance();
  const model = pulse.createEngagementModel({
    eventLabel,
    targetId
  });
  return pulse.track(model);
}

export function trackPageView(eventLabel: string, pageId: string) {
  const pulse = getInstance();
  const model = pulse.createPageViewModel(eventLabel, pageId);
  return pulse.track(model);
}

export function trackArticleView(articleId: string, category: string, access: string, title, authors: string[]) {
  const pulse = getInstance();
  const model = pulse.createArticleViewModel(articleId, category, access, title, authors);
  return pulse.track(model);
}
