import * as React from 'react';
import {IComponentImage} from '../../models/ArticleModel';
import {classNames, FontSize, Padding, TextAlign, TextColor} from '@snoam/pinata';

export const styleClass = {
  photoByLine: classNames(
    'photographer',
    'icon-camera',
    FontSize.TEXT_SM,
    TextColor.TEXT_NEUTRAL_5,
  )
};

export class ArticleImage extends React.Component<IComponentImage, {}> {

  render() {
    const {
      byline,
      imageAsset: {
        id,
        size: {
          height,
          width,
        }
      }
    } = this.props;
    return (
      <div className={classNames(Padding.PB_4, Padding.MD_PB_8)}>
        <div className={classNames("media-wrapper image-wrapper", TextAlign.TEXT_CENTER)}>
          <img src={`//ap.mnocdn.no/images/${id}?fit=crop${byline ? '&h=1152' : ''}&q=80&w=${byline ? 2048 : 980}`}
               data-original-width={width} data-original-height={height}/>
        </div>
        {byline && <div className={"media-info image-info"}>
					<div className={"caption"}>
                        <span className={styleClass.photoByLine}>
                            FOTO: {byline.title}
                        </span>
					</div>
				</div>}
      </div>
    );
  }

}

export default ArticleImage;
