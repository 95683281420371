import toPairs from "lodash/toPairs";

export default class PulseEventModel {

  protected store: Map<string, {}>;

  constructor(eventModel) {
    this.store = new Map(toPairs(eventModel));
  }

  setSpid(spid: number): PulseEventModel {
    return this;
  }

  setProduct(productName: string) {
    const origin = this.store.get('origin') || {};
    const custom = origin['spt:custom'] || {};
    custom['spt:product'] = productName;
    origin['spt:custom'] = custom;

    this.store.set('origin', origin);

    return this;
  };

  set deployStage(target) {
    this.store.set('deployStage', target);
  }

  set deployTag(tag) {
    this.store.set('deployTag', tag);
  }

  get object() {
    return this.store.get('object');
  }

  toObject() {
    const obj = {};
    this.store.forEach((v, k) => {
      if (v instanceof PulseEventModel) {
        obj[k] = v.toObject();
      } else {
        obj[k] = v;
      }

    });
    return obj;
  }
}
