import React, {Fragment} from 'react'
import {Popover, Transition} from '@headlessui/react'
import {MenuIcon} from '@heroicons/react/solid'
import {
  AlignItems,
  BackgroundColor,
  BoxShadow,
  classNames,
  Display,
  FontSize,
  FontWeight,
  Height,
  Margin,
  MaxWidth,
  Outline,
  Overflow,
  Padding,
  Position,
  TextAlign,
  TextColor,
  Width,
  ZIndex
} from "@snoam/pinata";
import {SpidContext, withSpidContext} from "@snoam/mono-spid";

const getItems = (loggedIn: boolean, spidContext: SpidContext) => {
  const items = [
    {
      name: 'Aftenposten.no',
      href: 'https://ap.no',
      onClick: null,
    },
    {
      name: 'Min side',
      href: 'https://kundeportal.aftenposten.no/minside',
      onClick: null,
    },

  ];

  if (loggedIn) {
    return [...items, {name: 'Logg ut', href: "#", onClick: () => spidContext.actions.logout()}]
  }

  return [...items, {name: 'Logg inn', href: "#", onClick: () => spidContext.actions.login({simplified: false})}]
}

function HeaderMenu({spidContext}) {
  const loggedIn = !!spidContext.state.userId;
  return (
    <Popover className={classNames(Position.RELATIVE)}>
      {({open}) => (
        <>
          <Popover.Button
            className={classNames(
              TextColor.TEXT_NEUTRAL_8,
              BackgroundColor.BG_WHITE,
              Display.INLINE_FLEX,
              AlignItems.ITEMS_CENTER,
              FontSize.TEXT_BASE,
              FontWeight.FONT_MEDIUM,
              Outline.OUTLINE_NONE,
              Padding.PT_2
            )}
          >
            <MenuIcon
              className={classNames(TextColor.TEXT_NEUTRAL_8, Margin.MX_2, Margin.MB_2, Height.H_6, Width.W_AUTO)}
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            as={Fragment}
          >
            <Popover.Panel className={
              classNames(
                Position.ABSOLUTE,
                Position.RIGHT_0,
                ZIndex.Z_DIALOG,
                MaxWidth.MAX_W_XS,
                Padding.SM_PX_0,
                Width.W_40,
              )
            }>
              <div className={
                classNames(
                  BoxShadow.SHADOW_MD,
                  Overflow.OVERFLOW_HIDDEN,
                )
              }>
                <div className={
                  classNames(
                    Position.RELATIVE,
                    BackgroundColor.BG_WHITE,
                    Padding.PX_5,
                    Padding.SM_P_8,
                    Padding.PY_6,
                    TextAlign.TEXT_RIGHT,
                  )
                }>
                  {getItems(loggedIn, spidContext).map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      onClick={item.onClick ? item.onClick : undefined}
                      className={classNames(
                        Margin.M_NEGATIVE_3,
                        Display.BLOCK,
                        Padding.P_3,
                      )}
                    >
                      <p className={
                        classNames(
                          FontSize.TEXT_BASE,
                          FontWeight.FONT_MEDIUM,
                          TextColor.TEXT_NEUTRAL_8,
                        )
                      }>{item.name}</p>
                    </a>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default withSpidContext(HeaderMenu);
