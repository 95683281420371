import {
  AlignItems,
  BackgroundColor,
  Button,
  ButtonSize,
  ButtonSkin,
  classNames,
  Container,
  ContainerWidth,
  Cursor,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  Height,
  Icon,
  IconColor,
  IconName,
  JustifyContent,
  Margin,
  Padding,
  Position,
  TextAlign,
  TextColor,
  TextDecoration,
  Width
} from '@snoam/pinata';
import * as React from 'react';
import {Link} from 'react-router-dom';
import {IWithRouterContext, withRouterContext} from '../../context/withRouterContext';
import {IWithAdminContext, withAdminContext} from '../../context/withAdminContext';
import {IWithSpidContext, withSpidContext} from "@snoam/mono-spid";
import {getQueryParams} from '../../tracking/Pulse';

export interface IHeaderProps extends IWithSpidContext, IWithRouterContext, IWithAdminContext {
  clientName: string,
  location: any,
}

interface IHeaderState {
  showMenu: boolean;
}

const styleClass = {
  header: () => classNames(
    BackgroundColor.BG_WHITE,
    Position.ABSOLUTE,
    TextColor.TEXT_BLACK,
    Position.TOP_0,
    Position.RIGHT_0,
    Position.LEFT_0,
    JustifyContent.JUSTIFY_BETWEEN,
    Padding.PX_2
  ),
  container: classNames(
    TextAlign.TEXT_CENTER,
    Display.FLEX,
    JustifyContent.JUSTIFY_BETWEEN,
    AlignItems.ITEMS_CENTER,
    Padding.PY_4,
    FontFamily.FONT_TITLE_2,
  ),
  title: classNames(
    TextColor.TEXT_WHITE,
    FontSize.TEXT_XL,
    FontSize.MD_TEXT_2XL,
    Padding.PL_2,
  ),
  headerElement: classNames(
    TextColor.TEXT_WHITE,
    FontSize.TEXT_SM,
    Display.FLEX,
    FlexDirection.FLEX_ROW,
    JustifyContent.JUSTIFY_CENTER,
    AlignItems.ITEMS_CENTER,
  ),
  link: classNames(
    TextDecoration.UNDERLINE,
    Padding.PL_4,
    Cursor.CURSOR_POINTER,
    Padding.PT_1,
  ),
  logo: classNames(
    Width.W_56,
  ),
  hiddenXs: classNames(
    Display.XS_HIDDEN,
    Display.MD_BLOCK,
  ),
  loggedInContainer: classNames(
    Height.H_8,
    TextColor.TEXT_NEUTRAL_8,
    Display.FLEX,
    FlexDirection.FLEX_ROW,
    AlignItems.ITEMS_CENTER,
    Margin.ML_AUTO,
    Padding.PR_4,
    Padding.MD_PR_0,
    Margin.MR_2
  )
};

class HeaderDesktop extends React.Component<IHeaderProps, IHeaderState> {

  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    }
  }

  private onRegisterMembershipClick = () => {
    const {becomeMemberLink} = this.props.adminContext.state.default;
    const referer = getQueryParams(this.props.location).referer;

    let fastflowlink = becomeMemberLink;
    if (fastflowlink.indexOf('?referer') > 0) {
      fastflowlink = fastflowlink.slice(0, fastflowlink.indexOf('?referer'))
    }
    window.location.href = fastflowlink + '?referer=' + (referer ? referer : 'Ext-vk-u_cta_coreheader_5ukerfor5kr');
  };

  private toggleMenu = () => {
    const showMenu = this.state.showMenu;
    this.setState({showMenu: !showMenu});
  };

  private renderNotLoggedInMenu = () => {
    return (
      <div className={styleClass.loggedInContainer}>
        <div className={styleClass.hiddenXs}>
          <Button text={"Bli medlem"} skin={ButtonSkin.PRIMARY} size={ButtonSize.XSMALL}
                  className={classNames(Margin.MY_0)} onClick={this.onRegisterMembershipClick}/>
        </div>
        <span className={classNames(styleClass.link, Padding.MD_PR_0)}
              onClick={() => this.props.spidContext.actions.login({simplified: false})}>LOGG INN</span>
      </div>
    );
  };

  private renderLoggedInMenu = () => {
    return (
      <div className={styleClass.loggedInContainer}>
        {!this.props.adminContext.state.hasProduct && this.props.adminContext.state.productLoaded &&
        <div className={styleClass.hiddenXs}>
            <Button text={"Bli medlem"} skin={ButtonSkin.PRIMARY} size={ButtonSize.XSMALL}
                    className={classNames(Margin.MY_0)} onClick={this.onRegisterMembershipClick}/>
        </div>
        }
        <div className={classNames(Padding.PR_4)}>
          {
            <span className={styleClass.link} onClick={() => this.props.spidContext.actions.logout()}>LOGG UT</span>
          }
        </div>
        <div className={classNames(Display.FLEX, AlignItems.ITEMS_CENTER, Cursor.CURSOR_POINTER)}>
          {
            <a className={classNames(styleClass.hiddenXs)}
               href="https://kundeportal.aftenposten.no/minside">Min side</a>
          }
        </div>
      </div>
    )
  };

  public render() {
    /**
     * Helper variable to toggle transparent background for header
     */
    const referer = getQueryParams(this.props.location).referer;

    return (
      <header className={styleClass.header()} style={{zIndex: 100}}>
        <Container className={styleClass.container} width={ContainerWidth.WIDER}>
          <div className={classNames(styleClass.headerElement, Padding.PL_4, Padding.MD_PL_0, Width.W_1_3)}>
            <Icon name={IconName.HOME} color={IconColor.NEUTRAL_8}/>
            <div className={classNames(Padding.PL_2, styleClass.hiddenXs)}>
              <a className={classNames(TextColor.TEXT_NEUTRAL_8, Padding.PT_1, Display.BLOCK)}
                 href={"https://ap.no"}>AP.NO</a>
            </div>
          </div>
          <Link to={'/' + (referer && '?referer=' + referer)}
                className={classNames(styleClass.headerElement, Width.W_1_3, JustifyContent.JUSTIFY_CENTER)}>
            <img src={'/images/a-vinklubben-logo-black.svg'} className={styleClass.logo} alt={"Vinklubb logo"}/>
          </Link>
          <div className={classNames(styleClass.headerElement, Width.W_1_3, TextAlign.TEXT_LEFT)}>
            {this.props.spidContext.state.userId ? this.renderLoggedInMenu() : this.renderNotLoggedInMenu()}
          </div>
        </Container>
      </header>
    );
  }
}

export default withSpidContext<any>(withRouterContext<any>(withAdminContext(HeaderDesktop)));
