import * as React from 'react';
import { Helmet } from 'react-helmet';
import FooterContainer from './components/Footer/FooterContainer';
import Header from './components/Header/Header'
import ClientContextProvider, {
  ClientContextConsumer,
  IClientContext,
  ClientContext,
} from './context/ClientContextProvider';
import { IThemeContext, ThemeConsumer, ThemeProvider } from './context/ThemeContext';
import { AdminProvider, IAdminContextState } from './context/AdminContextProvider';

import { Route } from 'react-router-dom'
import { routes } from './components/Router/Router';

import {classNames, FontFamily, LetterSpacing, Overflow, TextColor} from "@snoam/pinata";
import WPProvider from "./context/WPContext";
import { IArticle } from './models/ArticleModel';
import 'moment/locale/nb';
import FilterProvider from "./context/FilterContext";
import { SpidContextProvider, useSpid } from "@snoam/mono-spid";
import { default as getInstance, initialize } from "./tracking/Pulse";
import { ClientShortHandName, clientShortHandNameFromHostname } from "@snoam/mono-scc";
import { Sprite } from "./__generated__/sprite.symbol";
import { isLocal } from './utils';
import WebStorageService, {WebStorageServiceType} from "./shims/WebStorageService";

const debug = require('debug')('vinklubb:app');
const debugSpid = require('debug')('vinklubb:spidContext');

export interface IPreloadedState {
  adminContext?: IAdminContextState;
  article?: IArticle;
}

export interface IWithPreloadedState {
  preloadedState?: IPreloadedState;
}

export interface IAppProps extends IWithPreloadedState {
  domain: string;
  href: string;
  local?: boolean;
  location: any;
  reload?: (forcedReload?: boolean) => void;
}

const styleClass = {
  base: classNames(
    'App',
    FontFamily.FONT_BODY,
    LetterSpacing.TRACKING_NORMAL,
    TextColor.TEXT_NEUTRAL_6,
    Overflow.OVERFLOW_X_HIDDEN,
  )
};

const isServer = typeof window === 'undefined';
const storage = !isServer ? WebStorageService.getInstance(WebStorageServiceType.SessionStorage) : WebStorageService.getInstance(WebStorageServiceType.MemoryStorage);

const App: React.FC<IAppProps> = (props: IAppProps) => {

  const local = typeof props.local !== 'undefined' ? props.local : isLocal();

  React.useEffect(() => {
    const clientShortHandName: ClientShortHandName = clientShortHandNameFromHostname(props.domain);
    initialize(clientShortHandName);
  }, []);

  const setPulseUser = (userid: string) => {
    const pulse = getInstance();
    pulse.setUser(userid);
  };

  debug('props: %o', props);

  const { domain, href, preloadedState, location } = props;
  const WPContainerContent = () => {
    const spidContext = useSpid();
    const clientContext = React.useContext(ClientContext);

    return (
      <WPProvider hostname={clientContext.hostname} sig={spidContext.state.sig || ''}>
        <AdminProvider
          clientShortHandName={clientContext.clientShortHandName}
          preloadedState={preloadedState && preloadedState.adminContext}
          sig={spidContext.state.sig || ''}
        >
          <FilterProvider>
            <Header location={location} clientName={clientContext.clientName} />
            {routes.map((route, i) => (
              <Route key={i} {...route} />
            ))}
            <FooterContainer />
          </FilterProvider>
        </AdminProvider>
      </WPProvider>
    );
  };

  return (
    <div className={styleClass.base}>
      <ClientContextProvider
        domain={domain}
        href={href}
      >
        <SpidContextProvider {...props} siteSpecificLogout={!local} debug={debugSpid} setPulseUser={setPulseUser} storage={storage}>
          <ClientContextConsumer>{(clientContext: IClientContext) => (
            <React.Fragment>
              <ThemeProvider clientShortHandName={clientContext.clientShortHandName}>
                <ThemeConsumer>{(theme: IThemeContext) => (
                  <Helmet
                    defaultTitle={`${clientContext.clientShortHandName} | Aftenposten vinklubb`}
                    titleTemplate={`${clientContext.clientShortHandName} | Aftenposten vinklubb - %s`}
                  >
                    <link href={`/assets/css/${theme.themeCss}`} rel="stylesheet" />
                    <link href={`/assets/css/wineclub.css`} rel="stylesheet" />
                  </Helmet>
                )}
                </ThemeConsumer>
              </ThemeProvider>
              <WPContainerContent />
            </React.Fragment>
          )}
          </ClientContextConsumer>
        </SpidContextProvider>
      </ClientContextProvider>
      <Sprite />
    </div>
  );

}

export default App;
