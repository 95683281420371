import * as React from 'react';
import {match as IMatch} from 'react-router-dom';
import ArticleImage from './ArticleImage';
import ArticleList from './ArticleList';
import ArticleText from './ArticleText';
import {Helmet} from 'react-helmet';
import {
  BackgroundColor,
  BorderColor,
  BorderStyle,
  BorderWidth,
  classNames,
  Container,
  ContainerWidth,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  Heading,
  HeadingLevel,
  JustifyContent,
  LineHeight,
  Margin,
  MaxWidth,
  Padding,
  TextAlign,
  TextColor,
  TextTransform,
  Width,
} from '@snoam/pinata';
import {Component, ComponentType, IArticle, IComponentImage} from '../../models/ArticleModel';
import StateHandler from './StateHandler';
import {CapiService} from '../../services/CapiService';
import ArticleAuthors from "./ArticleAuthors";
import {IWithPreloadedState} from '../../App';
import Benefits from '../Benefits/Benefits';
import BecomeMember from '../BecomeMember/BecomeMember';
import {trackArticleView} from "../Pulse/Events";
import {useSpid} from '@snoam/mono-spid';

const debug = require('debug')('vinklubb:client:article');

export const filterTags = (tags: Array<{ id: string, title: string, type: string }>,) => {
  return tags.filter(tag => tag.type === 'descriptor');
};

export interface IProps extends IWithPreloadedState {
  article: { tease?: boolean } & Pick<IArticle,
    | 'id'
    | 'authors'
    | 'components'
    | 'title'
    | 'story'
    | 'tags'
    | 'changes'
    | 'section'
    | 'promotionContent'>;
}

export const styleClass = {
  root: classNames(
    BackgroundColor.BG_NEUTRAL_1,
    TextColor.TEXT_NEUTRAL_8,
    'snoam--article'
  ),
  tease: classNames(
    'snoam--article-tease',
  ),
  leadImage: classNames(
    'snoam--article-background-image',
    BackgroundColor.BG_PRIMARY_1,
  ),
  articleContent: classNames(
    'snoam--article-content',
  ),
  title: classNames(
    FontFamily.FONT_TITLE_1,
    LineHeight.LEADING_HERO,
    Padding.PT_6,
    Padding.PB_5,
    FontSize.MD_TEXT_4XL,
    FontSize.TEXT_3XL,
    FontWeight.FONT_NORMAL,
    TextAlign.TEXT_CENTER
  ),
  story: classNames(
    Margin.MB_2,
    FontSize.TEXT_SM,
    TextColor.TEXT_NEUTRAL_6,
    FontFamily.FONT_TITLE_2,
    BorderWidth.BORDER_B_2,
    BorderColor.BORDER_NEUTRAL_3,
    Padding.PB_2,
    MaxWidth.MAX_W_MD,
    Margin.MX_AUTO,
    FontWeight.FONT_LIGHT,
    TextTransform.UPPERCASE,
  ),
  intro: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    JustifyContent.JUSTIFY_CENTER,
    Margin.MX_AUTO,
    Padding.PB_4,
    BackgroundColor.BG_WHITE,
    Padding.PX_4,
    Padding.MD_PX_0,
  ),
  lead: classNames(
    FontFamily.FONT_TITLE_2,
    FontSize.MD_TEXT_LG,
    Padding.PB_4,
    TextAlign.TEXT_CENTER
  ),
  author: classNames(
    FontFamily.FONT_TITLE_2,
    TextAlign.TEXT_CENTER,
    Margin.MX_AUTO,
    FontSize.XS_TEXT_XS,
    TextTransform.UPPERCASE,
    FontWeight.FONT_NORMAL,
    Padding.PT_2,
    Margin.MT_8,
    BorderWidth.BORDER_T_2,
    BorderColor.BORDER_NEUTRAL_3,
    BorderStyle.BORDER_SOLID,
    MaxWidth.MAX_W_MD,
    TextColor.TEXT_NEUTRAL_7,
  ),
  content: classNames(
    Padding.PT_4,
    Width.MD_W_3_4,
    Margin.MX_AUTO,
    Padding.PX_4,
    Padding.MD_PX_0,
    Padding.PB_12,
  ),
  separator: classNames(
  ),
  tags: classNames(
    TextColor.TEXT_NEUTRAL_6,
    FontSize.TEXT_XS,
    FontFamily.FONT_TITLE_2,
    Padding.PT_4,
    Display.FLEX,
    TextTransform.UPPERCASE,
    JustifyContent.JUSTIFY_CENTER,
    'snoam--article-tags'
  )
};

const leadTextIndex = (components: Component[]): number => {
  const leadText = components.find(c => c.type === ComponentType.text && c.subtype === 'lead');
  return leadText ? components.indexOf(leadText) : -1;
}

const rest = (components: Component[]): Component[] => {
  return components.slice(leadTextIndex(components) + 1, components.length);
}

export const getArticleTease = (components: Component[]): Component[] => {
  const res = [...rest(components)];
  debug('rest: %o', res);
  const len = rest.length;
  res.splice(Math.floor(rest.length / 20), res.length);
  debug('SSR - returning partial article, rest.length went from %d to: %d', len, res.length);
  return res;
}

const renderComponent = (c: Component, i: number) => {
  switch (c.type) {
    case ComponentType.image: {
      return <ArticleImage key={i} {...c} />;
    }
    case ComponentType.list: {
      return <ArticleList key={i} {...c} />;
    }
    case ComponentType.text: {
      return <ArticleText key={i} {...c} />;
    }
  }
  debug('renderComponent(%o): null', c);
  return null;
}


export const Article: React.FC<IProps> = props => {


  React.useEffect(() => {
    const {article} = props;
    const category = article.section ? article.section.title : '';
    const type = article.story && article.story.title === 'vinklubb-featured-free' ? 'Free' : 'Paid';
    const authors = article.authors.map(author => author.title);
    const memberLevel = article.tease ? "non-member" : "member";
    trackArticleView(`${article.id}:${memberLevel}`, category, type, article.title.value, authors);
  }, []);

  const {
    article: {
      authors,
      story,
      components,
      title,
      changes,
      tags,
      section,
      tease,
      promotionContent
    },
  } = props;

  const articleLeadTextIndex = leadTextIndex(components);
  const articleLead = components.slice(0, articleLeadTextIndex + 1);
  const articleLeadImage = articleLead.find(c => c.type === ComponentType.image) as IComponentImage;
  const articleRest = tease ? getArticleTease(components) : rest(components);

  const description = promotionContent && promotionContent.description ? promotionContent.description.value : title.value;
  const mainImageUrl = articleLeadImage && articleLeadImage.imageAsset.id ?
    `//ap.mnocdn.no/images/${articleLeadImage && articleLeadImage.imageAsset.id}?fit=crop&h=1152&q=80&w=2048` :
    '//ap.mnocdn.no/images/89d5262a-3348-4b9c-ba3c-271dc7742cf5?fit=crop&h=1152&q=80&w=2048';

  const leadComponent = articleLead.slice(articleLeadTextIndex, articleLead.length + 1)
  const lead = leadComponent.map((c: any, i) => <ArticleText key={i} {...c}/>)

  return (
    <article className={styleClass.root}>
      <Helmet>
        <title>{title.value}</title>
        <meta property="og:title" content={title.value}/>
        <meta property="og:image" content={mainImageUrl}/>
        <meta property="og:image:width" content={'2048'}/>
        <meta property="og:image:height" content={'1152'}/>
        <meta property="og:description" content={description}/>
      </Helmet>
      <title>{title.value}</title>

      <div style={{backgroundImage: `url("${mainImageUrl}")`}} className={styleClass.leadImage}>
        &nbsp;
      </div>


      <div className={styleClass.articleContent}>

        <Container width={ContainerWidth.WIDE} className={styleClass.separator}>

          <div className={styleClass.intro}>
            <div
              className={classNames(Width.MD_W_3_4, Margin.MX_AUTO, Padding.PT_6, Margin.MD_MT_12, TextAlign.TEXT_CENTER)}>

              <Heading
                level={HeadingLevel.ONE}
                className={styleClass.title}>
                {title.value}
              </Heading>

              <p className={styleClass.lead}>{lead}</p>

              <ArticleAuthors
                authors={authors}
                changes={changes}
              />
            </div>
          </div>

        </Container>
        <Container width={ContainerWidth.WIDE} className={classNames(BackgroundColor.BG_WHITE, Padding.PB_12, Padding.PT_6)}>

          <div className={classNames(styleClass.content, tease ? styleClass.tease : '')}>
            {articleRest.map((c, i) => renderComponent(c, i))}
          </div>
          {tease ? <div className={classNames(styleClass.content, Display.FLEX, JustifyContent.JUSTIFY_CENTER)}>
            <BecomeMember extraTease={true}/>
          </div> : null}
        </Container>
      </div>
    </article>
  )
}

export default ({match, preloadedState}: { match: IMatch<{ articleId: string }> } & IWithPreloadedState) => {
  const spidContext = useSpid()
  const fetcher = async () => {
    const {articleId} = match.params;
    let user;
    try {
      user = await spidContext.actions.hasSession()
    } catch (e) {
      debug('usersession: %o', e);
    }
    return await new CapiService().fetchArticle(user ? (user || {sig: ""}).sig : '', articleId);
  };

  const renderWithData = (article: IArticle) => <Article article={article}/>;
  debug('preloadedState: %o', preloadedState);
  if (preloadedState && preloadedState.article && preloadedState.article.id === match.params.articleId) {
    return renderWithData(preloadedState.article);
  }
  return <StateHandler key={`user-${spidContext.state.sig}`} fetcher={fetcher} renderWithData={renderWithData}/>;
};
