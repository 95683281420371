import * as React from 'react';
import {ProjectIconColor, ProjectIconSize, ProjectIconTypes} from './ProjectIconTypes';
import {classNames, Margin, Padding} from '@snoam/pinata';

interface IProjectIconProps {
  name: ProjectIconTypes
  color?: ProjectIconColor
  size?: ProjectIconSize;
  className?: string;
}

const styleClass = {
  root: classNames(
    Margin.M_AUTO,
    Padding.P_2
  )
};

export default class ProjectIcon extends React.PureComponent<IProjectIconProps> {

  private static defaultProps = {
    color: ProjectIconColor.DEFAULT,
    size: ProjectIconSize.XL,
  };

  private renderIcon = () => {
    const {name, color, size, className} = this.props

    if(name) {
      return (
        <div style={{width: size, height: size}} className={classNames(className, styleClass.root)}>
          <img src={`/icons/${name}${color === ProjectIconColor.WHITE ? `_${color}` : ''}.svg`} style={{width: size, height: size}}/>
        </div>
      )
    } else {
      console.error("Colud not find icon ", name);
      return <span>NO</span>
    }
  };

  public render() {
    return (
      this.renderIcon()
    )
  }
}
