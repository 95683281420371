export enum PulseEventType {
  TRACKER_EVENT = 'trackerEvent'
}
export enum EventType {
  VIEW = 'View',
  SEND = 'Send',
  CLOSE = 'Close',
  ENGAGEMENT = 'Engagement'
}

export enum ObjectId {
  FORM = 'form',
  PAGE = 'page',
  CONFIRMATION = 'confirmation',
  CONTENT_ELEMENT = 'content:element'
}

export enum ObjectType {
  FORM = 'Form',
  PAGE = 'Page',
  CONFIRMATIOM = 'Confirmation',
  SUBSCRIPTION = 'Subscription',
  LISTING = 'Listing',
}


export enum EngagementAction {
  Click = "Click",
  Zoom = "Zoom",
  Pinch = "Pinch",
  Swipe = "Swipe",
  Drag = "Drag",
  Drop = "Drop",
  Spin = "Spin",
  Scroll = "Scroll"
}

export enum PulseBuilderType {
  ENGAGEMENT,
  PAGE_VIEW,
  FORM_SUBMIT,
}
