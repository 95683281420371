import {buildApiUrl, isLocal, isTest} from "../utils";


const debug = require('debug')('vinklubb:client:WPService');

export default class BenefitService {

  private checkError(res: Response): Response | any {
    if(res.status >= 200 && res.status < 300) {
      return res;
    }
    return Promise.reject(res);
  }

  public fetch<T>(url, params) {
    return fetch(url, params)
      .then(res => this.checkError(res))
      .then(res => res.json())
      .catch(err => {throw err});
  }

  public getBenefits(hostname: string, sig: string) {
    const categoryId = '17428937-d16d-45b8-afb1-9de59ba2454d';
    const url = `${buildApiUrl(hostname)}/api/perks/${categoryId}`;
    const options = {
      headers: {
        'Accept': 'application/json',
      },
      method: 'GET',
    };

    debug('getBenefits()');
    debug('calling fetch(%s, %o)', url, options);

    return this.fetch(url, options);
  }
}
