import {
  AlignItems,
  BackgroundColor,
  classNames,
  Container,
  ContainerWidth,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  Margin,
  MinHeight,
  Padding,
  Position,
  TextAlign,
  TextColor,
  ZIndex,
} from '@snoam/pinata';
import * as React from 'react';
import {createRef} from 'react';
import Experts from '../Expert/Experts';
import Hero from '../Hero/Hero';
import Navigation from '../Navigation/Navigation';
import ArticleList from '../ArticleList/ArticleList';
import Benefits from "../Benefits/Benefits";
import {trackPageView} from "../Pulse/Events";
import {IWithSpidContext, withSpidContext} from "@snoam/mono-spid";
import {IWithAdminContext, withAdminContext} from "../../context/withAdminContext";

const styleClass = {
  bodyText: Padding.PY_4,
  container: classNames(
    MinHeight.MIN_H_SCREEN,
    BackgroundColor.BG_PRIMARY_3,
  ),
  heading: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    AlignItems.ITEMS_CENTER,
    FontFamily.FONT_TITLE_1,
    FontSize.TEXT_MEGA,
    TextAlign.TEXT_CENTER,
    TextColor.TEXT_NEUTRAL_8,
    FontWeight.FONT_LIGHT,
    Padding.PT_8,
    Padding.PB_12,
    Margin.MB_4,
  ),
};

interface IBodyState {
  tracked: boolean;
}
type IWithSpidAndAdmin = IWithAdminContext & IWithSpidContext;
class Body extends React.Component<IWithSpidAndAdmin, IBodyState> {

  constructor(props) {
    super(props);
    this.state = {
      tracked: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.spidContext.state.isLoaded && !this.state.tracked) {
      const member = this.props.spidContext.state.hasProduct ? 'member' : 'non-member';
      trackPageView('View', `frontpage:${member}`);
      this.setState({tracked: true})
    }
  }

  private menuRef = createRef<HTMLDivElement>();

  private renderLoggedInBody = () => {
    return (
      <React.Fragment>

        <Hero/>
        <section
          className={classNames(BackgroundColor.BG_WHITE, TextColor.TEXT_WHITE, Padding.MD_PT_12)}>
          <Container width={ContainerWidth.WIDER}>
            <Navigation/>
          </Container>
        </section>

        <section className={classNames(BackgroundColor.BG_WHITE)} ref={this.menuRef}>
          <ArticleList menuRef={this.menuRef}/>
        </section>
        <Benefits/>
        <section className={classNames(Padding.PB_12)}>
          <Experts/>
        </section>
      </React.Fragment>
  )
  };

  private renderNotLoggedInBody = () => {
    return (
      <React.Fragment>
        <Hero/>
        <Experts/>
      </React.Fragment>
    )
  };

  public render() {
    const {userId} = this.props.spidContext.state;
    const {hasProduct} = this.props.adminContext.state;
    if (userId && hasProduct) {
      return this.renderLoggedInBody()
    }
    return this.renderNotLoggedInBody()
  }
}

export default withAdminContext<any>(withSpidContext(Body));
