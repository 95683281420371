import * as React from 'react';

import { Loader } from '@snoam/pinata';
import { Deferred } from '../../utils';

const debug = require('debug')('vinklubb:client:state-handler');

interface IStateHandlerProps<T> {
  renderWithData: (data: T) => JSX.Element;
  fetcher: () => Promise<T>;
}

interface IStateHandlerStateError {
  apiStatus: 'error';
  error: any;
}
interface IStateHandlerStateSuccess<T> {
  apiStatus: 'success';
  data: T;
}
type StateHandlerState<T> = {
  apiStatus: 'loading';
} | IStateHandlerStateError | IStateHandlerStateSuccess<T>;
class StateHandler<T> extends React.Component<IStateHandlerProps<T>, StateHandlerState<T>> {

  private loaderDeferred: Readonly<Deferred<T>>;
  private loaderPromise: Readonly<Promise<T>>;

  constructor(props: IStateHandlerProps<T>) {
    super(props);
    this.state = {
      apiStatus: 'loading',
    };
    this.loaderDeferred = new Deferred<T>();
    this.loaderPromise = this.loaderDeferred.promise;
  }

  public waitForLoad: () => Readonly<Promise<T>> = () => {
    return this.loaderPromise;
  };

  async componentWillMount() {
    try {
      const data = await this.props.fetcher();
      this.setState({ apiStatus: 'success', data });
    } catch (error) {
      this.setState({ apiStatus: 'error', error });
    }
  }

  render() {
    if (this.state.apiStatus === 'success') {
      const { data } = this.state;
      return this.props.renderWithData(data);
    } else if (this.state.apiStatus === 'loading') {
      return <Loader />;
    } else {
      const { error } = this.state;
      debug('error: %o', error);
      // If we get an error just route the user back to he homepage
      return <p>Error: {typeof error === 'object' ? error.statusText || JSON.stringify(error) : error}</p>
    }
  }
}

export default StateHandler;
