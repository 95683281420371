import React from 'react';
import { IArticle, Component, ComponentType } from '../../models/ArticleModel';
import {classNames, FontFamily, FontSize, FontWeight, LineHeight, Margin, Padding, TextColor} from '@snoam/pinata';
import ArticleAdminText from './ArticleAdminText';
import ArticleAdminList from './ArticleAdminList';

const debug = require('debug')('vinklubb:client:adminarticle');

export type IAdminArticle = { tease?: boolean } & Pick<IArticle,
  | 'components'
  | 'title'
  >;

export interface IProps {
  className?: string;
  article: IAdminArticle;
  styleClasses?: Record<string, any>;
}

const styleClass = {
  paragraph: classNames(
    FontFamily.FONT_TITLE_2,
    FontSize.MD_TEXT_LG,
    FontSize.TEXT_BASE,
    Padding.PY_1,
    LineHeight.LEADING_HEADLINE2,
  ),
  h2: classNames(
    FontFamily.FONT_TITLE_2,
    FontWeight.FONT_NORMAL,
  ),
  headingTextSub: classNames(
    FontSize.MD_TEXT_4XL,
    FontSize.TEXT_2XL,
    LineHeight.LEADING_HEADLINE1,
    Margin.MD_MT_6,
    Margin.MB_6,
    Margin.MT_1
  ),
};


export class AdminArticle extends React.Component<IProps, {}> {
  renderComponent(c: Component, i: number, styleClasses) {
    switch (c.type) {
      case ComponentType.list: {
        return <ArticleAdminList key={i} {...c} />;
      }
      case ComponentType.text: {
        return <ArticleAdminText key={i} {...c} styleClasses={styleClasses}/>;
      }
    }
    debug('renderComponent(%o): null', c);
    return null;
  }

  static leadTextIndex(components: Component[]): number {
    const leadText = components.find(c => c.type === ComponentType.text && c.subtype === 'lead');
    return leadText ? components.indexOf(leadText) : -1;
  }

  static rest(components: Component[]): Component[] {
    return components.slice(AdminArticle.leadTextIndex(components) + 1, components.length);
  }

  render() {
    const {
      className,
      article: {
        components
      },
      styleClasses = styleClass
    } = this.props;

    const rest = AdminArticle.rest(components);

    return (
      <div className={className}>
        {rest.map((c, i) => this.renderComponent(c, i, styleClasses))}
      </div>
    );
  }
}
