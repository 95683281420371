import React from 'react';
import {Subtract} from 'utility-types';
import {IWPActions, IWPContext, WPConsumer} from "./WPContext";

export interface IWithWPContext {
  actions: IWPActions,
}

export const withWPContext = <P extends IWithWPContext>(Component: React.ComponentType<P>) =>
  class WithWPContext extends React.PureComponent<Subtract<P, IWithWPContext>> {
    public render() {
      return (
        <WPConsumer>
          {
            (wpContext: IWPContext) => <Component
              {...this.props}
              actions={wpContext.actions}
            />
          }
        </WPConsumer>
      );
    }
  };
