import { IHasSessionSuccessResponse } from '@schibsted/account-sdk-browser';
import { ClientShortHandName, getClientId } from '@snoam/mono-scc';

const jwa = require('jwa');
const rp = require('request-promise-native');

export const verifyToken = (token: string, signSecret: string): Promise<IHasSessionSuccessResponse> => {
  const debug = require('debug')('vinklubb:crypto');

  if (!token) {
    const error = 'token is empty';
    debug(error + ': (%s)', token);
    return Promise.reject({ error });
  }

  if (!token.match(/^[^.]+\.[^.]+$/)) {
    const error = 'token has invalid format, expecting {sig}.{payload}';
    debug(error + ': (%s)', token);
    return Promise.reject({ error });
  }

  const signature = token.replace(/([^.]+).*/, '$1');
  const payload = token.replace(/([^.]+)\.([^.]+).*/, '$2');

  try {
    const generatedSignature = jwa('HS256').sign(payload, signSecret);
    if (signature !== generatedSignature) {
      const error = 'invalid signature';
      debug(error + ': (%s != %s)', signature, generatedSignature);
      return Promise.reject({ error });
    }

    const verifiedToken: IHasSessionSuccessResponse = JSON.parse(Buffer.from(payload, 'base64').toString());
    debug('signatureVerifiedToken: %o', verifiedToken);

    const expiryTime = verifiedToken.serverTime + verifiedToken.expiresIn;
    const now = Math.round((new Date()).getTime() / 1000);

    if (expiryTime < now) {
      const error = 'token expired ' + new Date(expiryTime * 1000).toISOString();
      debug('rejecting: %s', error);
      return Promise.reject({ error });
    }
    return Promise.resolve(verifiedToken);
  } catch (e) {
    return Promise.reject(e)
  }
};

export const getClientSecret = (env, clientShortHandName: ClientShortHandName): string | null => {
  return env[`SPID_${clientShortHandName}_client_secret`] || null;
};
export const getSignSecret = (env, clientShortHandName: ClientShortHandName): string | null => {
  return env[`SPID_${clientShortHandName}_sign_secret`] || null;
};

export const spidAccessToken = async (env, clientShortHandName: ClientShortHandName): Promise<{
  scope: 'openid profile',
  user_id: false | number,
  is_admin: boolean,
  token_type: 'Bearer',
  access_token: string,
  expires_in: number,
  server_time: number,
}> => {
  const debug = require('debug')('vinklubb:spid');
  const form: FormData = new FormData();
  form.append(`grant_type`, `client_credentials`);
  form.append(`scope`, `openid%20profile`);

  const clientId = getClientId(clientShortHandName, false);
  if (!clientId) {
    const error = `Couldn't find clientId for client "${clientShortHandName}"`;
    debug(`${error}, env: %o`, env);
    return Promise.reject({ error });
  }

  const clientSecret = getClientSecret(env, clientShortHandName);
  if (!clientSecret) {
    const error = `Couldn't find clientSecret for client "${clientShortHandName}"`;
    debug(`${error}, env: %o`, env);
    return Promise.reject({ error });
  }

  const options = {
    uri: 'https://identity-pre.schibsted.com/oauth/token',
    method: 'POST',
    form: {
      grant_type: `client_credentials`,
      scope: `openid profile`,
    },
    headers: {
      Authorization: `Basic ${btoa(`${clientId}:${clientSecret}`)}`,
      'Content-Type': `application/x-www-form-urlencoded`,
    },
  };
  const response = await rp(options);
  debug('options: %o, response: %o', options, response);
  return JSON.parse(response);
};

export const catchCAPIError = (response, res) => {
  const debug = require('debug')('vinklubb:api');
  if (response.statusCode && response.statusCode !== 200 || response.statusCode >= 299) {
    debug('Got error: %o', response);
    res.status(response.statusCode).json({
      err: response.message || "Something went wrong"
    });
  }
  return response;
}
