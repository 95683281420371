import * as React from 'react';
import {styleClass} from './Article';
import {IArticle} from "../../models/ArticleModel";
import ArticleAuthor from "./ArticleAuthor";
import moment from "moment";

const ArticleAuthors: React.FC<Pick<IArticle, 'authors' | 'changes'>> = (props) => {

  const {
    authors,
    changes,
  } = props;

  const joinedAuthors = authors.map((c, i) => (
    <React.Fragment key={i}>
      {i > 0 ? ', ' : null}<ArticleAuthor {...c}/>
    </React.Fragment>
  ));

  const hasAuthors = joinedAuthors.length > 0;

  const published = moment(changes.published).format('LL');

  return (
    <p className={styleClass.author}>{hasAuthors ? joinedAuthors : null} {hasAuthors ? ' |' : null} Publisert {published}</p>
  );
}

export default ArticleAuthors;
