import * as React from 'react';
import {IComponentText} from '../../models/ArticleModel';
import ArticleMarkup from './ArticleMarkup';
import {
  classNames,
  Padding,
  Heading,
  HeadingLevel,
  Margin,
  TextAlign, TextColor, FontFamily, FontWeight, FontSize, LineHeight
} from "@snoam/pinata";

export const styleClass = {
  paragraph: classNames(
    FontFamily.FONT_TITLE_1,
    FontSize.MD_TEXT_LG,
    FontSize.TEXT_BASE,
    Padding.PY_1,
  ),
  h2: classNames(
    FontFamily.FONT_TITLE_1,
    FontWeight.FONT_NORMAL,
  ),
  headingTextSub: classNames(
    FontSize.MD_TEXT_4XL,
    FontSize.TEXT_2XL,
    LineHeight.LEADING_HEADLINE1,
    Margin.MD_MT_6,

    Margin.MB_6,
    Margin.MT_1
  ),
};

export class ArticleAdminText extends React.Component<IComponentText, {}> {

  render() {
    const {
      subtype,
      text: {
        markup,
        value,
      },
      styleClasses = styleClass,
    } = this.props;

    if (subtype === 'heading') {
      return (
        <Heading level={HeadingLevel.FOUR} className={styleClasses.headingTextSub}>
          <ArticleMarkup value={value} markup={markup}/>
        </Heading>
      );
    }

    if (subtype === 'lead') {
      return <ArticleMarkup value={value} markup={markup}/>
    }

    return (
      <p className={styleClasses.paragraph}>
        <ArticleMarkup value={value} markup={markup}/>
      </p>
    );
  }

}

export default ArticleAdminText;
