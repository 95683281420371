import {IWithResponsiveProps, withResponsiveBrowserOnly} from "@snoam/pinata"
import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";
import * as React from 'react';
export const Header = withResponsiveBrowserOnly((props: IWithResponsiveProps & {location, clientName}) => {
  const {desktop, location, clientName} = props;
  if (desktop) {
    return <HeaderDesktop location={location} clientName={clientName}/>
  }
  return <HeaderMobile location={location} clientName={clientName}/>;
});

export default Header;
