import * as React from 'react';
import {useEffect, useState} from 'react';
import {
  AlignItems,
  BackgroundColor,
  BorderColor,
  BorderRadius,
  Button,
  ButtonSize,
  ButtonSkin,
  classNames,
  Cursor,
  Display,
  FlexDirection,
  FlexWrap,
  FontFamily,
  FontSize,
  FontWeight,
  Icon,
  IconName,
  IWithResponsiveProps,
  JustifyContent,
  Margin,
  MinWidth,
  Padding,
  Position,
  TextAlign,
  TextColor,
  TextDecoration,
  TextTransform,
  Width,
  withResponsive,
  ZIndex
} from "@snoam/pinata";
import {IWithAdminContext, withAdminContext} from "../../context/withAdminContext";
import {ICategory} from "../../services/AdminService";
import {IWithFilterContextProps, withFilterContext} from "../../context/withFilterContext";
import {IWithRouterContext, withRouterContext} from "../../context/withRouterContext";
import {getDefaultFilterId} from "../../utils";
import {RouterProps, withRouter} from 'react-router';

export interface INavigationProps extends IWithAdminContext, IWithResponsiveProps, IWithFilterContextProps, IWithRouterContext, RouterProps {
  className?: string,
}

interface INavigationState {
  showMobileMenu: boolean;
  filters: string[];
  mainCategoryId: string;
  subCategoryId: string;
}

const styleClass = {
  mobile: {
    root: classNames(
      Position.RELATIVE,
      BackgroundColor.BG_PRIMARY_1,
      FlexDirection.FLEX_COL,
      Display.FLEX,
      AlignItems.ITEMS_CENTER,
      FontSize.TEXT_BASE
    ),
    selectCategory: classNames(
      Padding.PX_4,
      Padding.PY_4,
      FontFamily.FONT_TITLE_2,
      Display.FLEX,
      AlignItems.ITEMS_CENTER,
      FontSize.TEXT_BASE
    ),
    menu: classNames(
      BackgroundColor.BG_PRIMARY_1,
      Position.ABSOLUTE,
      Margin.MT_12,
      Width.W_FULL,
      ZIndex.Z_50,
      FontFamily.FONT_TITLE_2,
      Padding.PB_6
    ),
    menuItem: {
      root: (selected: boolean) => classNames(
        {
          [BackgroundColor.BG_WHITE]: selected,
          [TextColor.TEXT_PRIMARY_1]: selected,
        },
        BackgroundColor.HOVER_BG_WHITE,
        BackgroundColor.FOCUS_BG_WHITE,
        TextColor.HOVER_TEXT_PRIMARY_1,
        Width.W_FULL,
        JustifyContent.JUSTIFY_START,
        Display.FLEX,
        FlexDirection.FLEX_COL,
      ),
      inner: (selected: boolean) => classNames(
        Margin.MX_AUTO,
        Margin.MY_4,
        MinWidth.MIN_W_32,
        Display.INLINE_BLOCK,
        Cursor.CURSOR_POINTER,
      ),
    },
    menuFlap: classNames(
      BackgroundColor.BG_WHITE,
      TextColor.TEXT_WHITE,
      FontSize.TEXT_XL,
      Padding.PX_6,
      Padding.PY_2,
      BorderRadius.ROUNDED_TL_FULL,
      BorderRadius.ROUNDED_TR_FULL,
      TextAlign.TEXT_CENTER,
      Margin.MX_AUTO,
      Position.ABSOLUTE,
      Position.LEFT_0,
      Position.RIGHT_0
    )
  },
  subCategoryContainer: classNames(
    Display.FLEX,
    FlexWrap.FLEX_WRAP,
    JustifyContent.JUSTIFY_CENTER,
    FontSize.TEXT_XS,
    Padding.PB_2,
    TextTransform.UPPERCASE
  ),
  subCategory: (selected: boolean) => classNames(
    Margin.MX_2,
    TextColor.TEXT_PRIMARY_1,
    BorderColor.BORDER_WHITE,
    BorderColor.HOVER_BORDER_WHITE,
    TextDecoration.HOVER_UNDERLINE,
    Cursor.CURSOR_POINTER,
    {
      [FontWeight.FONT_BOLD]: selected,
      [TextDecoration.UNDERLINE]: selected,
    },
  )

};

export const Navigation: React.FC<INavigationProps> = (props) => {

  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const [activeCategory, setActiveCategory] = useState<string | null>(getDefaultFilterId());
  const [activeSubCategory, setActiveSubCategory] = useState<string | null>(null);

  const {
    filterContext,
    adminContext,
    history,
    mobile,
  } = props;

  useEffect(() => {
    const {
      mainCategory: {
        id: mainCategoryId = null
      } = {},
      subCategory: {
        id: subCategoryId = null,
      } = {}
    } = filterContext;

    if (mainCategoryId !== getDefaultFilterId()) {
      setActiveCategory(mainCategoryId);
    }
    if (subCategoryId) {
      setActiveSubCategory(subCategoryId);
    }
  }, []);

  const toggleMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };


  const addMainCategory = (categoryId: string) => {
    history.replace({search: ''});
    filterContext.setMainCategory(categoryId);

    if (activeCategory === categoryId) {
      setActiveCategory(null);
      setActiveSubCategory(null);
      setShowMobileMenu(!showMobileMenu);
    } else {
      setActiveCategory(categoryId);
      setActiveSubCategory(null);
      setShowMobileMenu(!showMobileMenu);
    }
  };

  const addSubCategory = (categoryId: string) => {
    if(activeSubCategory === categoryId) {
      filterContext.setSubCategory(null);
      setActiveSubCategory(null);
    } else {
      filterContext.setSubCategory(categoryId);
      setActiveSubCategory(categoryId);
    }
  };

  const renderSubCategories = (cats: ICategory[]) => {
    return cats.map((cat, i) => (
        <Button
          key={cat.id}
          className={classNames(styleClass.subCategory(activeSubCategory === cat.id))}
          onClick={() => addSubCategory(cat.id)}
          skin={ButtonSkin.SECONDARY}
          size={ButtonSize.XSMALL}
          text={cat.name}
        />
    ))
  };

  const DesktopFilter = () => {
    const categories = adminContext.state.categories;
    const subCategories = getSubCategories();


    const renderMainCategories = categories.map((cat) => {
      const selected = activeCategory === cat.mainCategory.id;
      return (
        <Button
          skin={selected ? ButtonSkin.SECONDARY : ButtonSkin.PRIMARY}
          size={ButtonSize.SMALL}
          text={cat.mainCategory.name}
          key={cat.mainCategory.id}
          onClick={() => addMainCategory(cat.mainCategory.id)}
          className={classNames(
            Margin.MX_2,
            {
              [TextColor.TEXT_PRIMARY_1]: selected,
              [BorderColor.BORDER_PRIMARY_1]: selected
            })}
        />
      )
    });

    return (
      <div style={{maxWidth: '1000px'}} className={Margin.MX_AUTO}>
        <div className={classNames(Display.FLEX, FlexWrap.FLEX_WRAP, JustifyContent.JUSTIFY_CENTER)}>
          {renderMainCategories}
        </div>
        {subCategories && subCategories.length > 0 &&
        <div className={styleClass.subCategoryContainer}>
          {renderSubCategories(subCategories)}
        </div>
        }
      </div>);
  };

  const MobileFilter = () => {
    const {categories} = adminContext.state;
    const subCategories = getSubCategories();

    return (
      <div className={styleClass.mobile.root}>
        <p className={styleClass.mobile.selectCategory} onClick={toggleMenu}>
          Velg kategori {showMobileMenu ? <Icon name={IconName.ARROW_DROP_UP}/> :
          <Icon name={IconName.ARROW_DROP_DOWN}/>}
        </p>
        {showMobileMenu &&
        <div className={styleClass.mobile.menu}>
          {categories.map(cat =>
            <div
              key={cat.mainCategory.id}
              className={styleClass.mobile.menuItem.root(activeCategory === cat.mainCategory.id)}
              onClick={() => addMainCategory(cat.mainCategory.id)}
            >
              <span className={styleClass.mobile.menuItem.inner(activeCategory === cat.mainCategory.id)}>
                {cat.mainCategory.name}
              </span>
            </div>
          )}
        </div>
        }
        {subCategories && subCategories.length > 0 &&
        <div className={styleClass.subCategoryContainer}>
          {renderSubCategories(subCategories)}
        </div>
        }
      </div>
    )
  };

  const getSubCategories = (): ICategory[] => {
    const categories = adminContext.state.categories;

    if (activeCategory) {
      const category = categories.find(cat => cat.mainCategory.id === activeCategory);
      if (category) {
        return category.subCategory;
      }
    }
    return []
  };

  if (mobile) {
    return <MobileFilter key={`mobile-${activeCategory}-${activeSubCategory}`}/>
  }
  return <DesktopFilter key={`desktop-${activeCategory}-${activeSubCategory}`}/>
}


export default withAdminContext(
  withResponsive<any>(
    withFilterContext<any>(
      withRouterContext<any>(
        withRouter(Navigation as any)
      )
    )
  )
);
