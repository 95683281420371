import * as React from 'react';
import { IWithMarkup } from '../../models/ArticleModel';
import { ArticleExternalLink } from './ArticleExternalLink';

const debug = require('debug')('vinklubb:client:article-markup');

export interface IProps extends IWithMarkup {
  value: string;
}

export class ArticleMarkup extends React.Component<IProps, {}> {

  render() {
    const {
      markup,
      value,
    } = this.props;

    if (!markup || !markup.length) {
      return value;
    }

    markup.forEach(m1 => {
      const found = markup.find(m2 => m1 !== m2 && m1.offset <= m2.offset && (m1.offset + m1.length) > m2.offset);
      if (found) {
        debug('markup %o conflicts with %o', m1, found);
      }
    });

    const [m, ...rest] = markup;
    debug('m: %o, rest: %o, value: %s', m, rest, value);
    const regex = new RegExp(`(.{${m.offset}})?(.{${m.length}})(.*)`);
    const match = value.match(regex);
    if (match) {
      switch (m.type) {
        case 'link:external': {
          return (
            <React.Fragment>
              {match[1] || null}
              <ArticleExternalLink uri={m.uri}>
                <ArticleMarkup value={match[2]} markup={rest}/>
              </ArticleExternalLink>
              {match[3] ? <ArticleMarkup value={match[3]} markup={rest.map(r => Object.assign({}, r, {offset: r.offset - (m.offset + m.length) }))}/> : null}
            </React.Fragment>
          );
        }
        case 'style:em': {
          return (
            <React.Fragment>
              {match[1] || null}
              <em>
                <ArticleMarkup value={match[2]} markup={rest}/>
              </em>
              {match[3] ? <ArticleMarkup value={match[3]} markup={rest.map(r => Object.assign({}, r, {offset: r.offset - (m.offset + m.length) }))}/> : null}
            </React.Fragment>
          );
        }
        case 'style:strong': {
          return (
            <React.Fragment>
              {match[1] || null}
              <strong>
                <ArticleMarkup value={match[2]} markup={rest}/>
              </strong>
              {match[3] ? <ArticleMarkup value={match[3]} markup={rest.map(r => Object.assign({}, r, {offset: r.offset - (m.offset + m.length) }))}/> : null}
            </React.Fragment>
          );
        }
        default: {
          debug('Unknown markup: %o', markup);
        }
      }
    }

    return value;
  }

}

export default ArticleMarkup;
