import * as React from 'react';
import { Component, createContext } from 'react';

const debug = require('debug')('vinklubb:routerContext');

export interface IRouterContext {
  location: {
    host: string;
    href: string;
    origin: string;
    pathname: string;
    port: string;
    search: string;
  };
}

const RouterContext = createContext<IRouterContext>({
  location: typeof window !== 'undefined' ? window.location : {
    host: '',
    href: '',
    origin: '',
    pathname: '',
    port: '',
    search: '',
  },
});

export const RouterContextConsumer = RouterContext.Consumer;

export class RouterContextProvider extends Component<IRouterContext, {}> {

  static displayName = 'RouterContextProvider';

  constructor(props: IRouterContext) {
    super(props);
  }

  public render() {
    return (
      <RouterContext.Provider value={this.props}>
        {this.props.children}
      </RouterContext.Provider>
    )
  }
}
