import React from 'react';
import { IRouterContext, RouterContextConsumer } from './RouterContextProvider';
import { Subtract } from 'utility-types';

export interface IWithRouterContext {
  routerContext: IRouterContext;
}

export const withRouterContext = <P extends IWithRouterContext>(Component: React.ComponentType<P>) =>
  class WithRouterContext extends React.PureComponent<Subtract<P, IWithRouterContext>> {
    public render() {
      return (
        <RouterContextConsumer>
          {
            (routerContext: IRouterContext) => <Component
              {...this.props}
              routerContext={routerContext}
            />
          }
        </RouterContextConsumer>
      )
    }
  };
