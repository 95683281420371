import {MemoryStorage} from "./MemoryStorage";

const storageAvailable = (storage: Storage) => {
  try {
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};
const isSSR = typeof window === "undefined";

export interface IStorageService {
  readonly length: number;

  clear(): void;

  getItem(key: string): string | null;

  key(index: number): string | null;

  removeItem(key: string): void;

  setItem(key: string, data: string): void;
}

export enum WebStorageServiceType {
  LocalStorage = 'localStorage',
  SessionStorage = 'sessionStorage',
  MemoryStorage = 'memoryStorage',
}

const StorageTypeToService = {
  [WebStorageServiceType.LocalStorage]: () => localStorage,
  [WebStorageServiceType.SessionStorage]: () => sessionStorage,
  [WebStorageServiceType.MemoryStorage]: () => new MemoryStorage(),
}

let instance: IStorageService | null = null;

export default class WebStorageService implements IStorageService {

  public static getInstance(type?: WebStorageServiceType) {
    if (!instance) {
      instance = new WebStorageService(type);
      if (!isSSR) {
        (window as any).foobar = instance;
      }
    }
    return instance;
  }

  private static APPLICATION_KEY = 'MP__';

  private storage: IStorageService;

  private constructor(type: WebStorageServiceType = WebStorageServiceType.LocalStorage) {

    if (isSSR) {
      this.storage = new MemoryStorage();
      return;
    }
    try {
      const wantedStorage = StorageTypeToService[type]();
      if (!storageAvailable(wantedStorage)) {
        this.storage = new MemoryStorage();
      } else {
        this.storage = wantedStorage;
      }
    } catch (_) {
      this.storage = new MemoryStorage();
    }
  }

  public get length(): number {
    return this.storage.length;
  }

  public clear(): void {
    this.storage.clear();
  }

  public getItem(key: string): string | null {
    return this.storage.getItem(`${WebStorageService.APPLICATION_KEY}${key}`);
  }

  public key(index: number): string | null {
    return this.storage.key(index);
  }

  public removeItem(key: string): void {
    this.storage.removeItem(`${WebStorageService.APPLICATION_KEY}${key}`);
  }

  public setItem(key: string, data: string): void {
    this.storage.setItem(`${WebStorageService.APPLICATION_KEY}${key}`, data);
  }
}
