import * as querystring from "querystring";
import { Request, Response } from 'express';
import { RequestPromiseOptions } from 'request-promise-native';
const request = require('request-promise-native');

export const handleCapiRequest: (path: string, req: Readonly<Request>, res: Response, direct: boolean) => Promise<any> = (path, req, res, direct) => {
  const user = process.env.key;
  const pw = process.env.secret;
  const uri = path.indexOf("articles/") > 0 ? `https://${process.env.domain}${path}?${querystring.stringify(req.query)}` : `https://${process.env.domain}${path}`;
  const auth = `${user}:${pw}`;
  const options = {
    method: req.method,
    uri,
    headers: {
      Authorization: 'Basic ' + Buffer.from(auth).toString('base64')
    },
    json: true,
  };

  if(direct) {
    return directRequest(options, res);
  } else {
    return internalRequest(options);
  }

};

const directRequest: (options: RequestPromiseOptions, res: Response) => Promise<any> = (options, res) => {
  return request(options)
  .then(parsedBody => res.json(parsedBody))
  .catch(err => {
    res.status(err.statusCode || 500).json({
      error: err.error || 'Internal server error'
    });
  });
};

const internalRequest: (options: RequestPromiseOptions) => Promise<any> = (options) => {
  return request(options)
  .then(parsedBody => parsedBody)
  .catch(err => {
    return Promise.reject(err);
  });
};

export const openCapi = (req, res) => {
  const reqQuery = req.url.split("?")[0];
  if(req.url.includes('collections/v1/vin/articles?story')) {
    handleCapiRequest(reqQuery, req, res, true);
  } else {
    res.status(401).json({
      error: "No Access"
    })
  }
};

