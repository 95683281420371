import * as React from "react";

interface ILoaderProps {
  isLoading: boolean;
}

const Loader: React.FC<ILoaderProps> = ({ isLoading }) => (
  <>
    {isLoading && (
      <div className="lds-ellipsis">
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
      </div>
    )}
  </>
);

export default Loader;
