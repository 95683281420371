import {Tracker} from '@spt-tracking/pulse-sdk';
import {parse as parseQueryString} from 'qs';
import {isTest} from "../utils";
import {ClientShortHandName} from "@snoam/mono-scc";
import {EngagementAction, EventType, ObjectType, PulseEventType} from "./EventEnums";
import PulseEventModel from "./PulseEventModel";


/* tslint:disable: prefer-const */
let pulse: Pulse;
/* tslint:enable: prefer-const */

export const getQueryParams = (location = {search: ''}, defaultReferer = '') => {
  const search = location.search;
  const qs = parseQueryString(search.substr(1, search.length));
  if (!qs.referer) {
    qs.referer = defaultReferer;
  }
  return qs;
};

const getPulseProviderId: (k: keyof typeof ClientShortHandName) => string = k =>
  ({
    AP: 'aftenposten',
    ASK: 'askoyvaeringen',
    BTI: 'bt',
    BYN: 'bygdanytt',
    DNB: 'aftenposten',
    DP: 'dinepenger',
    FAK: 'lister',
    FVN: 'faedrelandsvennen',
    LIN: 'lindesnes',
    MPL: 'aftenposten-magasin-pluss',
    SA: 'stavangeraftenblad',
    SCH: 'schibsted',
    STR: 'strilen',
    SYS: 'sysla',
    VG: 'vg',
    VNY: 'vestnytt',
  }[k]);

export class Pulse {

  tracker: Tracker;
  queue: string[];
  user: any;

  constructor(public merchant: ClientShortHandName) {
    this.tracker = new Tracker(getPulseProviderId(merchant));
    this.queue = [];
  }

  setUser(user) {
    this.user = user;
    this.tracker.update({actor: this.actor})
  }

  private internalTracking(model: any) {

    if (!isTest) {
      return Promise.resolve(void(0));
    }

    if (isTest()) {
      model.deployStage = 'dev';
      model.deployTag = 'PulseUnicorn';
    }

    return this.tracker.track(PulseEventType.TRACKER_EVENT, model.toObject());
  }

  private get provider() {
    return {
      type: 'Organization',
      id: getPulseProviderId(this.merchant),
    }
  }

  private get actor() {
    return {
      "id": this.user,
      "realm": 'spid.no'
    }
  }

  public track(model: PulseEventModel): any {
    return this.internalTracking(model);
  }

  public createEngagementModel({eventLabel, targetId}) {
    const label = `VK ${eventLabel}`;

    return new PulseEventModel({
      action: EngagementAction.Click,
      object: {
        '@id': `sdrn:${getPulseProviderId(this.merchant)}:page:vinklubb:element:${targetId}`,
        type: 'UIElement',
        elementType: 'Button',
        name: label,
        url: location.origin+'/kategori/'+targetId,
      },
      origin: {
        url: location.href,
      },
      provider: this.provider,
      type: EventType.ENGAGEMENT,
    });
  }

  public createPageViewModel(eventLabel: string, pageId: string) {
    const label = `VK ${eventLabel}`;

    return new PulseEventModel({
      object: {
        name: label,
        '@id': `sdrn:${getPulseProviderId(this.merchant)}:listing:vinklubb:${pageId}`,
        type: ObjectType.LISTING,
        url: location.href
      },
      origin: {
        url: location.origin
      },
      actor: this.actor,
      provider: this.provider,
      type: EventType.VIEW,
    });
  }

  public createArticleViewModel(articleId: string, category: string, access: string, title, authors: string[]) {
    const label = `VK View article`;

    return new PulseEventModel({
      object: {
        '@id': `sdrn:${getPulseProviderId(this.merchant)}:article:${articleId}`,
        '@type': 'Article',
        'accessLevel': access,
        category,
        'contentId': articleId,
        name: title,
        url: location.href,
        authors
      },
      origin: {
        url: location.origin
      },
      actor: this.actor,
      provider: this.provider,
      type: EventType.VIEW,
    })
  }
}

export default function getInstance() {
  if (!pulse) {
    return new Pulse(ClientShortHandName.SCH);
  }
  return pulse;
}

export function initialize(merchant: ClientShortHandName) {
  pulse = new Pulse(merchant);
  return pulse;
}
