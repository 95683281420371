import { ClientShortHandName } from '@snoam/mono-scc';

export interface IExpert {
  name: string;
  bio: string;
  imgUrl: string;
  imgAltText: string;
  pageUrl: string;
}

export interface IMenu {
  text: string;
  url: string;
  external: boolean;
}

export interface IDefault {
  hero: {
    loggedIn: {
      bgImage: string;
      titleOne: string;
      titleTwo: string;
      bylineOne: string;
      bylineTwo: string;
    }
    notLoggedIn: {
      bgImage: string;
      titleOne: string;
      titleTwo: string;
      bulletList: string[];
      bylineOne: string;
    }
  }
  benefitsLink: string;
  becomeMemberLink: string;
  becomeMemberText: string;
  homepageLink: string;
}

export interface ICategories {
  mainCategory: ICategory
  subCategory: [ICategory];
}

export interface ICategory {
  name: string;
  id: string;
  type: string;
  newsroom: string;
}


export class AdminService {

  private readonly clientShortHandName: ClientShortHandName;

  constructor(clientShortHandName: ClientShortHandName) {
    this.clientShortHandName = clientShortHandName;
  }

  public fetchExperts(): Promise<IExpert[]> {
    // Temp fetching of config data until admin is in place
    const expertConfig = require(`../config/${this.clientShortHandName}/expert.json`) as IExpert[];
    return Promise.resolve(expertConfig);
  }

  public fetchMenu(): Promise<IMenu[]> {
    const menuConfig = require(`../config/${this.clientShortHandName}/menu.json`) as IMenu[];
    return Promise.resolve(menuConfig);
  }

  public fetchDefault(): Promise<IDefault> {
    const defaultConfig = require(`../config/${this.clientShortHandName}/default.json`) as IDefault;
    return Promise.resolve(defaultConfig)
  }

  public fetchCategories(): Promise<ICategories[]> {
    const categoriesConfig = require(`../config/${this.clientShortHandName}/categories.json`) as ICategories[];
    return Promise.resolve(categoriesConfig)
  }


}
