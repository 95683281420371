import * as React from 'react';
import {createContext} from 'react';
import {IWithAdminContext, withAdminContext} from "./withAdminContext";
import {ICategories, ICategory} from "../services/AdminService";
import {RouterProps, withRouter} from "react-router";
import {getDefaultFilterId, slugifyIt} from "../utils";

import * as queryString from 'query-string';
import {Location} from 'history';
import {trackEngagement} from '../components/Pulse/Events';

export interface IFilterContext {
  filterUri: string;
  setMainCategory: (id: string) => void;
  setSubCategory: (id: string | null) => void;
  mainCategory: ICategory;
  subCategory: ICategory;
}

export interface IFilterContextProps extends IWithAdminContext, RouterProps {
  location: Pick<Location, 'pathname' | 'search'>,
}

const FilterContext = createContext<IFilterContext>({} as IFilterContext);

export const FilterConsumer = FilterContext.Consumer;

export interface IFilterProviderState {
  filterUri: string;
  mainCategory: ICategory;
  subCategory: ICategory;
}

export class FilterProvider extends React.Component<IFilterContextProps, IFilterProviderState> {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  private getInitialState() {
    const main = this.props.adminContext.state.categories.find(cat => cat.mainCategory.id === getDefaultFilterId()) as ICategories;
    return {
      filterUri: `?story=${getDefaultFilterId()}`,
      mainCategory: main ? main.mainCategory : {id: getDefaultFilterId()} as ICategory,
      subCategory: {} as ICategory
    }
  }

  public setMainCategory = (categoryId: string) => {
    if (categoryId === this.state.mainCategory.id) {
      this.props.history.replace({pathname: '/'});
      return this.setState(this.getInitialState());
    }

    const category = this.props.adminContext.state.categories.find(cat => cat.mainCategory.id === categoryId) as ICategories;
    if (category) {
      return this.setState({subCategory: {} as ICategory, mainCategory: category.mainCategory});
    }
    this.props.history.replace({search: ''});
    return this.setState(this.getInitialState());
  };

  public setSubCategory = (subCategoryId: string | null) => {
    if (!subCategoryId) {
      this.setState({subCategory: {} as ICategory});
    } else {
      const {mainCategory} = this.state;
      const subCategories = this.props.adminContext.state.categories.find(cat => cat.mainCategory.id === mainCategory.id) as ICategories;
      const subCategory = subCategories.subCategory.find(cat => cat.id === subCategoryId) as ICategory;
      this.setState({subCategory});
    }
  };

  public updateFilter = () => {

    const {mainCategory, subCategory} = this.state;

    const queryParams = queryString.parse(this.props.location.search);
    const {from, size} = queryParams;

    if (mainCategory.name) {
      trackEngagement(`Click on section: ${mainCategory.name}`, slugifyIt(mainCategory.name))
    }

    const buildCategoryUrl = (category: ICategory, url: URL, pathname: URL, pathRoot: string = '') => {
      url.searchParams.set(category.type, category.id);
      url.searchParams.set('newsroom', category.newsroom);
      if(pathRoot) {
        pathname.pathname = `${pathRoot}/${slugifyIt(category.name)}`;
      } else {
        pathname.pathname = `${pathname.pathname === "/" ? "" : pathname.pathname}/${slugifyIt(category.name)}`;
      }

      return [url, pathname];
    }

    const url: URL = new URL(window.location.href), pathname: URL = new URL(window.location.href);

    if (Object.keys(subCategory).length > 0) {
      const {type} = subCategory;

      if (type !== "section") {
        let [url$1, pathname$1] = buildCategoryUrl(mainCategory, url, pathname, '/kategori');
        let [url$2, pathname$2] = buildCategoryUrl(subCategory, url$1, pathname$1, '');
        url$2.searchParams.forEach((value, key) => {url.searchParams.set(key, value)});
        pathname.pathname = pathname$2.pathname;
      } else {
        buildCategoryUrl(subCategory, url, pathname, `/kategori/${slugifyIt(mainCategory.name)}`);
      }
    } else {
      if (Object.keys(mainCategory).length > 0 && mainCategory.name) {
        buildCategoryUrl(mainCategory, url, pathname, '/kategori');
      }
    }

    this.props.history.replace({
      pathname: pathname.pathname,
      search: size && from ? `?from=${from}&size=${size}` : ''
    });
    this.setState({filterUri: url.search});
  };

  public componentDidUpdate(prevProps: IFilterContextProps, prevState: IFilterProviderState) {

    if (prevProps.adminContext.state.categories !== this.props.adminContext.state.categories) {
      this.mapSlugToId();
    }

    if (prevState.mainCategory !== this.state.mainCategory || prevState.subCategory !== this.state.subCategory) {
      this.updateFilter();
    }
  }

  public mapSlugToId = () => {
    const {location} = this.props;
    if (location) {
      const {categories} = this.props.adminContext.state;
      const path = location.pathname.split('/');
      const mainCategorySlug = path.length > 1 ? path[2] : '';
      const subCategorySlug = path.length > 2 ? path[3] : '';
      const relevantCategories = categories.find(cat => slugifyIt(cat.mainCategory.name) === mainCategorySlug) as ICategories;

      if (relevantCategories) {
        const mainCategory = relevantCategories.mainCategory;
        const subCategory = relevantCategories.subCategory ? relevantCategories.subCategory.find(cat => slugifyIt(cat.name) === subCategorySlug) as ICategory : null;

        this.setState({
          mainCategory,
          subCategory: subCategory ? subCategory : {} as ICategory
        })
      }
    }
  };

  public render() {
    const state = {
      filterUri: this.state.filterUri,
      mainCategory: this.state.mainCategory,
      subCategory: this.state.subCategory,
      setMainCategory: this.setMainCategory,
      setSubCategory: this.setSubCategory,
    };

    return (
      <FilterContext.Provider value={state}>
        {this.props.children}
      </FilterContext.Provider>
    )

  }
}

export default withAdminContext<any>(withRouter<any>(FilterProvider));

