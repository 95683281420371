import React, {useEffect, useState} from "react";
import {
  AlignItems,
  BackgroundColor,
  Button,
  classNames,
  Container,
  ContainerWidth,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  Grid,
  GridComposition,
  GridGutter,
  Heading,
  IWithResponsiveProps,
  JustifyContent,
  LineHeight,
  Margin,
  MaxWidth,
  Padding,
  TextAlign,
  TextColor,
  withResponsive,
} from '@snoam/pinata';
import BenefitCard from './BenefitCard';
import {IWithSpidContext, withSpidContext} from "@snoam/mono-spid";
import {IWithWPContext, withWPContext} from "../../context/withWPContext";
import {IWithAdminContext, withAdminContext} from "../../context/withAdminContext";
import {camelizeKeys} from "humps";
import {ProjectIconSize, ProjectIconTypes} from "../Icon/ProjectIconTypes";
import ProjectIcon from "../Icon/ProjectIcon";


interface IBenefitsProps extends IWithSpidContext, IWithWPContext, IWithAdminContext, IWithResponsiveProps {
  className?: string;
}

export interface IBenefit {
  id: number,
  image: string;
  label: {
    color: string;
    text: string;
  }
  link: string;
  newsletter_title: string;
  order: number;
  price: string;
  priceRegular: string;
  priceSavings: string;
  published: string;
  star: boolean;
  time: string;
  title: string,
}

const styleClass = {
  root: classNames(
    Padding.PX_4,
    Padding.MD_PX_0,
    BackgroundColor.BG_NEUTRAL_2,
  ),
  heading: classNames(
    Margin.MY_0,
    Padding.PT_16,
    Padding.PB_6,
    FontFamily.FONT_TITLE_1,
    TextAlign.TEXT_CENTER,
    FontSize.TEXT_4XL,
    LineHeight.LEADING_TIGHT,
    TextColor.TEXT_NEUTRAL_8,
  ),
  description: classNames(
    TextAlign.TEXT_CENTER,
    MaxWidth.MD_MAX_W_LG,
    Margin.MX_AUTO,
    Margin.MB_12,
    FontSize.TEXT_BASE,
    LineHeight.LEADING_HEADLINE3,
    FontFamily.FONT_TITLE_2,
    TextColor.TEXT_NEUTRAL_8,
  ),
  grid: classNames(
    AlignItems.ITEMS_STRETCH,
    JustifyContent.JUSTIFY_CENTER,
    Margin.MR_NEGATIVE_4,
    Margin.ML_NEGATIVE_4,
    Margin.MD_M_0,
  ),
  link: classNames(
    AlignItems.ITEMS_STRETCH,
    Display.FLEX,
    Padding.MD_P_3,
  ),
  seeMore: {
    root: classNames(
      Display.FLEX,
      FlexDirection.FLEX_COL,
      JustifyContent.JUSTIFY_CENTER,
      AlignItems.ITEMS_CENTER,
      Margin.MT_8,
      Margin.MR_3,
      Padding.PY_12,
    ),
  },
};


const Benefits: React.FC<IBenefitsProps> = (props) => {
  const [benefits, setBenefits] = useState<IBenefit[] | null>(null);

  const {mobile, tablet, desktop} = props;


  useEffect(() => {
    props.actions.getBenefits()
      .then(res => {
        const {
          products = []
        } = res as {
          products: IBenefit[]
        };
        setBenefits(camelizeKeys(products.slice(0, 3)) as IBenefit[]);
      })
      .catch(err => console.log(err));
  }, []);

  if (!benefits || benefits.length === 0) {
    return null;
  }

  const composition = desktop || tablet ? GridComposition.ALPHA : GridComposition.DEFAULT;
  const gutters: GridGutter[] = [];

  if (mobile && !tablet) {
    gutters.push(GridGutter.LEFT_NONE, GridGutter.RIGHT_NONE);
  } else if (tablet) {
    gutters.push(GridGutter.BOTTOM_NONE);
  } else {
    gutters.push(GridGutter.LEFT_NONE, GridGutter.BOTTOM_NONE);
  }

  return (
    <div className={styleClass.root}>
      <Container width={ContainerWidth.WIDER} className={MaxWidth.MAX_W_6XL}>
        <Heading className={styleClass.heading}>
          <ProjectIcon
            name={ProjectIconTypes.BENEFITS}
            size={ProjectIconSize.L}
            className={Margin.MB_6}
          />
          Fordeler
        </Heading>
        <div className={styleClass.seeMore.root}>
            <Button
              href={'https://www.aftenposten.no/fordel/mat-og-drikke'}
              text={'Se tilbud på vinkurs og messer'}
              className={Padding.PX_10}
            />
        </div>
      </Container>
    </div>
  );
};

export default withResponsive<any>(
  withSpidContext<any>(
    withWPContext<any>(
      withAdminContext(Benefits as any)
    )
  )
);
