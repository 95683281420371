import {
  AlignItems,
  BackgroundColor,
  Button,
  ButtonSize,
  ButtonSkin,
  classNames,
  Container,
  ContainerWidth,
  Cursor,
  Display,
  Flex,
  FlexDirection,
  FlexShrink,
  FontSize,
  FontWeight,
  Height,
  JustifyContent,
  Margin,
  Padding,
  Position,
  TextColor,
  TextDecoration,
  Width
} from '@snoam/pinata';
import * as React from 'react';
import {Link} from 'react-router-dom';
import {IWithRouterContext, withRouterContext} from '../../context/withRouterContext';
import {IWithAdminContext, withAdminContext} from '../../context/withAdminContext';
import {IWithSpidContext, withSpidContext} from "@snoam/mono-spid";
import {getQueryParams} from '../../tracking/Pulse';
import HeaderMenu from "./HeaderMenu";

export interface IHeaderProps extends IWithSpidContext, IWithRouterContext, IWithAdminContext {
  clientName: string,
  location: any,
}

interface IHeaderState {
  showMenu: boolean;
}

const styleClass = {
  header: () => classNames(
    BackgroundColor.BG_WHITE,
    FlexShrink.FLEX_SHRINK_0,
  ),
  container: {
    root: classNames(
      Width.W_FULL,
      Margin.MX_AUTO,
      Padding.PX_2,
      Padding.SM_PX_2,
      Padding.LG_PX_8,
    ),
    inner: classNames(
      Position.RELATIVE,
      Display.FLEX,
      AlignItems.ITEMS_CENTER,
      Height.H_16,
    )
  },
  title: classNames(
    TextColor.TEXT_WHITE,
    FontSize.TEXT_XL,
    FontSize.MD_TEXT_2XL,
    Padding.PL_2,
  ),
  headerElement: classNames(
    TextColor.TEXT_WHITE,
    FontSize.TEXT_SM,
    Display.FLEX,
  ),
  link: classNames(
    TextDecoration.UNDERLINE,
    Padding.PL_4,
    Cursor.CURSOR_POINTER,
  ),
  logo: classNames(
    Width.MD_W_64,
    Width.W_56,
  ),
  hiddenXs: classNames(
    Display.XS_HIDDEN,
    Display.MD_BLOCK,
  ),
  loggedInContainer: classNames(
    Height.H_8,
    Display.FLEX,
    FlexDirection.FLEX_ROW,
    AlignItems.ITEMS_CENTER,
    Margin.ML_AUTO,
    Padding.PR_4,
    Padding.MD_PR_0,
    Margin.MR_2
  )
};

class HeaderMobile extends React.Component<IHeaderProps, IHeaderState> {

  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    }
  }

  private onRegisterMembershipClick = () => {
    const {becomeMemberLink} = this.props.adminContext.state.default;
    const referer = getQueryParams(this.props.location).referer;

    let fastflowlink = becomeMemberLink;
    if (fastflowlink.indexOf('?referer') > 0) {
      fastflowlink = fastflowlink.slice(0, fastflowlink.indexOf('?referer'))
    }
    window.location.href = fastflowlink + '?referer=' + (referer ? referer : 'Ext-vk-u_cta_coreheader_5ukerfor5kr');
  };

  private toggleMenu = () => {
    const showMenu = this.state.showMenu;
    this.setState({showMenu: !showMenu});
  };


  private renderLoggedInMenu = () => {
    return (
      <div className={styleClass.loggedInContainer}>
        <div className={classNames(Padding.PR_4)}>
          {
            <span className={styleClass.link} onClick={() => this.props.spidContext.actions.logout()}>LOGG UT</span>
          }
        </div>
        <div className={classNames(Display.FLEX, AlignItems.ITEMS_CENTER, Cursor.CURSOR_POINTER)}>
          {
            <a className={classNames(styleClass.hiddenXs, TextColor.TEXT_WHITE)}
               href="https://kundeportal.aftenposten.no/minside">Min side</a>
          }
        </div>
      </div>
    )
  };

  public render() {

    const referer = getQueryParams(this.props.location).referer;

    const becomeMemberBtn = (
      <Button
        text={"Bli medlem"}
        skin={ButtonSkin.PRIMARY}
        size={ButtonSize.XSMALL}
        className={classNames(Margin.MY_0, FontWeight.FONT_LIGHT, Margin.MR_4, Display.HIDDEN, Display.MD_BLOCK)}
        onClick={this.onRegisterMembershipClick}
      />
    );
    return (
      <header className={styleClass.header()} style={{zIndex: 100}}>
        <Container
          className={styleClass.container.root}
          width={ContainerWidth.WIDER}
        >
          <div className={styleClass.container.inner}>
            <div className={classNames(Width.LG_W_1_3, Width.W_0,)}/>

            <div
              className={classNames(Width.LG_W_1_3, Width.W_2_6, Flex.FLEX_1, Display.FLEX, JustifyContent.JUSTIFY_CENTER, AlignItems.ITEMS_CENTER, FlexShrink.FLEX_SHRINK_0)}>

              <div
                className={classNames(Width.W_FULL, Padding.PX_2, Padding.LG_PX_6, AlignItems.ITEMS_CENTER, JustifyContent.JUSTIFY_CENTER, Display.FLEX)}>


                <Link
                  to={'/' + (referer && '?referer=' + referer)}
                  className={Display.INLINE_FLEX}
                >
                  <img
                    className={classNames(Height.H_10, Width.W_AUTO)}
                    src={"/images/a-vinklubben-logo-black.svg"}
                    alt="Aftenposten vinklubb logo"
                  />
                </Link>

              </div>
            </div>


            <div className={classNames(
              styleClass.headerElement,
              JustifyContent.XL_JUSTIFY_CENTER,
              JustifyContent.MD_JUSTIFY_START,
              JustifyContent.JUSTIFY_END,
              AlignItems.ITEMS_CENTER,
              Padding.PR_4,
              Padding.MD_PR_0,
              Width.W_1_3
            )}>
              {!this.props.spidContext.state.hasProduct && becomeMemberBtn}
              <HeaderMenu/>
            </div>
          </div>

        </Container>
      </header>
    );
  }
}

export default withSpidContext<any>(withRouterContext<any>(withAdminContext(HeaderMobile)));
