import * as React from 'react';
import { ComponentType, IComponentList } from '../../models/ArticleModel';
import { ArticleText } from './ArticleText';

export class ArticleList extends React.Component<IComponentList, {}> {

    render() {
        const {
            subtype,
            items,
        } = this.props;

        const liItems = items.map((e, i) => (
            <li key={i}>
                <ArticleText type={ComponentType.text} text={{ ...e }}/>
            </li>
        ));

        if (subtype === 'ordered') {
            return (
                <ol>
                    {liItems}
                </ol>
            );
        }

        return (
            <ul style={{paddingInlineStart: '1rem'}}>
                {liItems}
            </ul>
        );
    }

}

export default ArticleList;
