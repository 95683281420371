import * as React from 'react';
import {
  AlignItems,
  Button,
  ButtonSize,
  ButtonSkin,
  classNames,
  Display,
  FontWeight, JustifyContent,
  TextColor,
  Width,
} from '@snoam/pinata';
import {IWithAdminContext, withAdminContext} from '../../context/withAdminContext';
import {getQueryParams} from '../../tracking/Pulse';

export interface IProps extends IWithAdminContext {
  skin?: ButtonSkin;
  text?: TextColor;
  extraTease?: boolean;
}

class BecomeMember extends React.Component<IProps, {}> {

  static displayName = "BecomeMember";

  constructor(props: IProps) {
    super(props);
  }


  public render() {
    const {becomeMemberLink, becomeMemberText} = this.props.adminContext.state.default;
    const {extraTease, skin = ButtonSkin.SECONDARY, text = TextColor.TEXT_PRIMARY_1} = this.props;
    let referer = '';
    if (typeof window !== "undefined") {
      referer = getQueryParams(window.location).referer || '';
    }
    let fastflowlink = becomeMemberLink;
    if (fastflowlink.indexOf('?referer') > 0) {
      fastflowlink = fastflowlink.slice(0, fastflowlink.indexOf('?referer'))
    }
    return (
      <div>
        {extraTease ? (
          <div>Bli medlem for å lese hele artikkelen</div>
        ) : null}
        <Button
          skin={skin}
          text={becomeMemberText}
          size={ButtonSize.MEDIUM}
          className={classNames(Width.W_AUTO, FontWeight.FONT_LIGHT, text)}
          href={fastflowlink + '?referer=' + (referer ? referer : 'Ext-vk-u_cta_coreheader_5ukerfor5kr')}
        />
      </div>
    );
  }
}

export default withAdminContext(
  BecomeMember
);
