import * as React from 'react';
import { IComponentAuthor } from '../../models/ArticleModel';

export class ArticleAuthor extends React.Component<IComponentAuthor, {}> {

  render() {
    const {
      id,
      title,
    } = this.props;

    return (
      <span itemProp="author" itemType="http://schema.org/Person" key={id}>{title}</span>
    );
  }

}

export default ArticleAuthor;
