import { handleCapiRequest } from '../RequestHandlers';
import { catchCAPIError } from '../Utils';
import { NextFunction, Request, Response } from 'express';
import { SpidClient } from '../SpidClient';
import { isProduction } from '@snoam/mono-spid';
import { IArticle } from '../../models/ArticleModel';
import Article, { getArticleTease } from '../../components/Article/Article';
import {resolveJwtFromCookies} from "../../utils";

const debug = require('debug')('vinklubb:CapiApi');
export class Capi {

  private readonly req: Readonly<Request>;
  private readonly res: Readonly<Response>;
  private readonly next: Readonly<NextFunction>;
  private readonly freeStory: Readonly<string>;
  private readonly adminSection: Readonly<string>;
  private readonly sectionId: Readonly<string>;
  private readonly productCode: Readonly<string>;

  constructor(req: Request, res: Response, next: NextFunction) {
    this.res = res;
    this.req = req;
    this.next = next;
    this.freeStory = "vinklubb-featured-free";
    this.adminSection = "46311e54-0158-485c-834c-c4edefad7ba2"; // Admin section
    this.sectionId = "8130a321dcc64908ba23bf2043aafcf5"; // Amagsinet sectionID
    this.productCode = process.env.REACT_APP_SPID_PRODUCT_ID!;
  }

  /**
   *
   * @param {*} search
   * Main enpoint for getting articles based on tags/story and free search,
   */
  async getArticlesByTag() {
    const hasProduct = await this.hasProduct();
    debug("GetArticleHasProduct", hasProduct)
    if(hasProduct) {
      this.getSections();
      const capiRequestUrlVin: string = `/collections/v1/${this.req.query.newsroom ? this.req.query.newsroom : 'vin'}/articles/${this.req.query.q ? 'search': ''}`;
      debug('GetArticlesByTag(), Capi request url %s', capiRequestUrlVin);
      debug('GetArticlesByTag(), Capi request query %o', this.req.query);
      const articles = await this.getArticlesForNewsroom(capiRequestUrlVin);
      this.res.json(articles);
    } else {
      this.res.status(400).json({error: `No access to product ${this.productCode}`});
    }
  }

  async ping(articleId: string = '5V7ePm'): Promise<IArticle> {
    return handleCapiRequest(`/entities/v1/ap,vin/article/${articleId}`, this.req, this.res, false);
  }

  getSections() {
    if(this.req.query.newsroom && this.req.query.newsroom.includes('ap')) {
      this.req.query.section = this.sectionId;
    }
  }

  async getArticlesForNewsroom(capiRequestUrl: string): Promise<IArticle[]> {
    return handleCapiRequest(capiRequestUrl, this.req, this.res, false)
      .then(res => catchCAPIError(res, this.res))
      .then(response => {
        debug("articles: %o", response.items);
        return this.getArticles(response.items)
      })
      .then(articles => {
        return articles.filter(Boolean);
      })
  }

  /**
   * Main endpoint for getting a slimed version of articles based the free article story in
   * newsroom vin.
   */
  getFreeArticles() {
    handleCapiRequest('/collections/v1/vin/stories', this.req, this.res, false)
      .then(stories => catchCAPIError(stories, this.res))
      .then(result => this.getStoryMarkedAsFree(result))
      .then(storyId => this.getArticleIdsByStory(storyId))
      .then(articles => this.getArticles(articles))
      .then(fullArticles => this.res.json(fullArticles))
  }

  getArticles(articles: Array<Pick<IArticle, 'id'>>): Promise<IArticle[]> {
    debug(articles);
    const results = articles.map(async (article) => {
      return await this.getArticle(article.id, false)
    });
    return Promise.all(results);
  }

  getArticle(articleId, protectedArticle: boolean = false): Promise<IArticle & { tease?: boolean }> {
    return handleCapiRequest(`/entities/v1/ap,vin/article/${articleId}`, this.req, this.res, false)
      .then((articleRes) => catchCAPIError(articleRes, this.res))
      .then(async (articleData) => {
        const queryString = Object.keys(this.req.query).length ? ('?' + Object.keys(this.req.query).map(k => `${k}=${this.req.query[k]}`).join('&')) : '';
        if (articleData.story && articleData.story.title === this.freeStory) {
          debug('Got **free** article from %s%s', this.req.url, queryString);
          return Promise.resolve(articleData);
        } else {
          if (protectedArticle) {
            debug('Got paid article from %s%s', this.req.url, queryString);
            const hasProduct = await this.hasProduct();
            debug(`hasProduct: %s`, hasProduct);
            if(hasProduct) {
              return Promise.resolve(articleData);
            } else {
              debug(`No access to product ${this.productCode}, returning partial article`);
              return Promise.resolve({ tease: true, ...articleData, components: getArticleTease(articleData.components) });
            }
          } else {
            debug(`Got **paid** article from %s%s, but protectedArticle is ${protectedArticle}`, this.req.url, queryString);
            return Promise.resolve(articleData);
          }
        }
      });
  }

  getStoryMarkedAsFree(result) {
    if (result.items && result.items.length > 0) {
      const filtered = result.items.filter((story) => {
        if (story.model.title === this.freeStory) {
          return story;
        }
      })[0];
      return filtered.id;
    }
    this.res.status(401).json({ error: "Could not find story" });
  }

  /**
   * Get admin articles from CAPI.
   */

  getAdmin() {
    this.getArticleBySection(this.adminSection)
      .then(result => this.getArticles(result))
      .then(fullArticles => this.res.json(fullArticles))
  }

  traverseAndSlimArticle(allArticles) {
    const slimArticles: any = [];
    allArticles.map((article) => {
      const slimedArticle = {
        id: article.id,
        title: article.title,
        tags: article.tags,
        image: article.promotionContent
      };
      slimArticles.push(slimedArticle);
    });
    return slimArticles;
  }

  getArticleIdsByStory(storyId) {
    return handleCapiRequest(`/collections/v1/vin/articles?story=${storyId}`, this.req, this.res, false)
      .then((articleRes) => catchCAPIError(articleRes, this.res))
      .then(res => {
        const articles: any = [];
        res.items.map((article) => articles.push(article));
        return articles;
      });
  }

  getArticleBySection(sectionId) {
    return handleCapiRequest(`/collections/v1/vin/articles?section=${sectionId}`, this.req, this.res, false)
      .then((articleRes) => catchCAPIError(articleRes, this.res))
      .then(res => {
        const articles: any = [];
        res.items.map((article) => articles.push(article));
        return articles;
      });
  }

  async hasProduct(): Promise<boolean> {
    const sClient = new SpidClient({
      clientId: process.env.SPID_AP_client_id!,
      clientSecret: process.env.SPID_AP_client_secret!,
      isProduction: isProduction(this.req.hostname),
    });
    try {
      if(this.req.headers.cookie) {
        const jwt = resolveJwtFromCookies(this.req.headers.cookie, isProduction(this.req.hostname))
        const hasProduct: any = await sClient.product(jwt || "", this.productCode);
        debug("********* %o", hasProduct);
        return hasProduct.entitled;
      } else {
        return false;
      }
    } catch (e) {
      debug('CapiApi error %o', e);
      return false;
    }
  }

}
