import * as React from 'react';
import {IComponentText} from '../../models/ArticleModel';
import ArticleMarkup from './ArticleMarkup';
import {classNames, FontFamily, FontWeight, Heading, HeadingLevel, Padding, TextColor} from "@snoam/pinata";

export const styleClass = {
    paragraph: classNames(
      TextColor.TEXT_NEUTRAL_8,
      FontFamily.FONT_TITLE_2,
      Padding.PT_1,
      Padding.PB_3,
    ),
    h2: classNames(
      FontFamily.FONT_TITLE_2,
      TextColor.TEXT_NEUTRAL_8,
      FontWeight.FONT_NORMAL,
      Padding.PB_1,
    ),
};

export class ArticleText extends React.Component<IComponentText, {}> {

    render() {
        const {
            subtype,
            text: {
                markup,
                value,
            },
        } = this.props;

        if (subtype === 'heading') {
            return (
                <Heading level={HeadingLevel.TWO} className={styleClass.h2}>
                  <ArticleMarkup value={value} markup={markup}/>
                </Heading>
            );
        }

        if(subtype === 'lead') {
            return <ArticleMarkup value={value} markup={markup} />
        }

        return (
            <p className={styleClass.paragraph}>
              <ArticleMarkup value={value} markup={markup}/>
            </p>
        );
    }

}

export default ArticleText;
