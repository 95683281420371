import React from 'react';
import BenefitService from "../services/BenefitService";

const debug = require('debug')('vinklubb:client:WPContext');

export interface IWPContextProps {
  hostname: string,
  sig: string
}

export interface IWPActions {
  getBenefits: () => Promise<any>;
}

export interface IWPContext {
  actions: IWPActions;
  state: any;
}

const WPContext = React.createContext<IWPContext>({
  state: {} as any,
  actions: {} as any
});


export const WPConsumer = WPContext.Consumer;

class WPProvider extends React.Component<IWPContextProps> {

  private service: BenefitService;

  constructor(props) {
    super(props);
    this.service = new BenefitService();
  }

  public componentDidMount() {
    return;
  }

  private getBenefits = () => {
    const {hostname, sig} = this.props;

    return this.service.getBenefits(hostname, sig);
  };

  public render() {
    const {state} = this;

    const actions = {
      getBenefits: this.getBenefits
    };

    const context = {
      actions,
      state
    };

    return <WPContext.Provider value={context}>
      {this.props.children}
    </WPContext.Provider>
  }
}

export default WPProvider;
