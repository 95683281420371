import * as React from 'react';
import {
  BackgroundColor,
  BorderColor,
  BorderWidth,
  classNames,
  Display,
  FlexDirection,
  FontFamily,
  FontSize, LineHeight,
  Link,
  Margin,
  Padding,
  TextAlign,
  TextColor,
  TextDecoration,
  TextTransform,
  Whitespace,
  Width
} from '@snoam/pinata';
import {ComponentType, IArticle, IComponentText} from '../../models/ArticleModel';
import {getQueryParams} from '../../tracking/Pulse';

interface IArticleCardProps {
  article: IArticle;
}

const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    TextDecoration.NO_UNDERLINE
  ),
  imgContainer: classNames(
    Width.W_FULL
  ),
  image: classNames(
    'snoam--articlecard-image'
  ),
  body: classNames(
    TextAlign.TEXT_LEFT,
    Display.FLEX,
    FlexDirection.FLEX_COL,
    BackgroundColor.BG_WHITE
  ),
  description: classNames(
    FontSize.TEXT_SM,
    TextColor.TEXT_NEUTRAL_5,
    FontFamily.FONT_TITLE_1,
  ),
  categoryContainer: classNames(
    TextColor.TEXT_NEUTRAL_6,
    FontSize.TEXT_XS,
    Whitespace.TRUNCATE,
    TextTransform.UPPERCASE,
    FontFamily.FONT_TITLE_2,
    Padding.PT_3,
    Padding.PB_1
  ),
  category: classNames(
    Display.BLOCK,
  ),
  bodyText: classNames(
    Padding.PT_2,
    FontFamily.FONT_TITLE_1,
    TextColor.TEXT_NEUTRAL_8,
    LineHeight.LEADING_HEADLINE2,
  ),
  author: classNames(
    TextColor.TEXT_NEUTRAL_5,
    FontSize.TEXT_XS,
    TextTransform.UPPERCASE,
    Margin.MT_AUTO
  )
};

const CategorySlug = ({article}) => {
  const {tags = []} = article;

  if (tags.length === 0) {
    return (<div className={styleClass.categoryContainer}/>);
  }

  const [tag] = tags;

  return (
    <div className={classNames(styleClass.categoryContainer, BorderColor.BORDER_NEUTRAL_6,
      BorderWidth.BORDER_B_1)}>
        <span className={styleClass.category}>
          {tag.title}
        </span>
    </div>
  )
};

const LeadText = ({article}) => {
  const desc = article.components.find((c) => c.type === ComponentType.text) as IComponentText;
  return (
    <p className={styleClass.description}>
      {desc.text.value}
    </p>
  )
};

const Title = ({article}) => {
  let titleToUse;
  const {
    title: articleTitle,
    promotionContent: {
      title: promotionTitle = null
    } = {}
  } = article;
  if (promotionTitle && promotionTitle.value) {
    titleToUse = `${promotionTitle.value.substr(0, 80)}${promotionTitle.value.length > 80 ? '...' : ''}`;
  } else {
    titleToUse = `${articleTitle.value.substr(0, 80)}${articleTitle.value.length > 80 ? '...' : ''}`;
  }

  return (
    <p className={styleClass.bodyText} style={{fontSize:'1.375rem'}}>
      {titleToUse}
    </p>
  )
};

const Author = ({article}) => {
  const {authors} = article;
  if (authors.length === 0) {
    return null;
  }
  const [primaryAuthor] = authors;
  return <p className={styleClass.author}>{`AV: ${primaryAuthor.title}`}</p>
}

const ArticleCard: React.FC<IArticleCardProps> = ({article}) => {

  const referer = (window && window.location) ? getQueryParams(window && window.location).referer : '';
  const {
    promotionContent: {
      imageAsset = null
    } = {}
  } = article;
  return (
    <Link
      className={classNames(styleClass.root, Width.W_FULL)}
      href={`/artikkel/${article.id + (referer && '?referer=' + referer)}`}
      style={{maxWidth: '356px'}}
    >
      <div className={styleClass.imgContainer} style={{height: '200px'}}>
        <img
          src={imageAsset ? `//ap.mnocdn.no/images/${imageAsset.id}?fit=crop&q=80&w=380` : '/images/hero-default-bg.png'}
          className={styleClass.image}
          data-original-width={imageAsset ? imageAsset.size.width : 200}
          data-original-height={imageAsset ? imageAsset.size.height : 200}
        />
      </div>

      <div className={styleClass.body} style={{height: '160px'}}>
        <CategorySlug article={article}/>
        <Title article={article}/>
      </div>
    </Link>
  )
}

export default ArticleCard;
