import { IStorageService } from './WebStorageService';
let _memStorage = {}
export class MemoryStorage implements IStorageService {

  private getKeys(): string[] {
    return Object.keys(_memStorage);
  }

  get length(): number {
    return this.getKeys().length;
  }

  clear(): void {
    _memStorage = {};
  }

  getItem(key: string): string | null {
    return _memStorage[key] || null;
  }

  key(index: number): string | null {
    return this.getKeys()[index] || null;
  }

  removeItem(key: string): void {
    delete _memStorage[key];
  }

  setItem(key: string, data: string): void {
    _memStorage[key] = data;
  }
}
