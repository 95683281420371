import { OptionsWithUri } from 'request-promise-native';
import { IHasSessionSuccessResponse } from '@schibsted/account-sdk-browser';
import { clientShortHandNameFromHostname } from '@snoam/mono-scc';
import { getSignSecret, verifyToken } from './Utils';

const debug = require('debug')('vinklubb:spid-client');
const rp = require('request-promise-native');
const btoa = require('btoa');
export interface IInitOptions {
  clientId: string;
  clientSecret: string;
  isProduction: boolean;
}

export interface ISpidAccessToken {
  access_token: string;
  expires_in: number;
  is_admin: boolean;
  refresh_token: string;
  scope: 'openid profile';
  server_time: number;
  user_id: false | number;
  token_type: 'Bearer';
}

export interface ISpidApiResponse<T> {
  name: 'SPP Container';
  version: '0.2';
  api: 2;
  object: string;
  type: 'collection';
  code: number;
  request: {
    reset: number;
    limit: number;
    remaining: number;
  };
  debug: {
    route: {
      name: string;
      url: string;
      controller: string;
    };
    params: {
      options: { [k: string]: string };
      where: { [k: string]: string };
    };
  };
  meta: {
    count: number;
    offset: number;
  },
  error: any;
  data: T[];
}

export interface ISpidApiResponseProducts extends ISpidApiResponse<{
  [k: string]: {
    userProductId: string;
    productId: string;
    bundleId: string;
    parentProductId: string;
    clientId: string;
    userId: string;
    orderId: string;
    status: string;
    count: string;
    updated: string;
    created: string;
    statusMsg: string;
  };
}> {}

export interface ISpidApiResponseProduct extends ISpidApiResponse<{
  productId: string;
  result: boolean;
}> {}

const baseUri = (isProduction: boolean) => isProduction ? `https://payment.schibsted.no` : `https://identity-pre.schibsted.com`;
const oAuthUri = (isProduction: boolean) => isProduction ? `https://payment.schibsted.no/oauth/token` : `${baseUri(isProduction)}/oauth/token`;
const apiUri = (isProduction: boolean) => `${baseUri(isProduction)}/api/2`;
const accessServiceApiUrl = (isProduction: boolean) => isProduction ? "https://access.schibsted.digital" : "https://access.pre.schibsted.digital"

export class SpidClient {

  private readonly clientId: Readonly<string>;
  private readonly clientSecret: Readonly<string>;
  private readonly isProduction: Readonly<boolean>;

  constructor(options: IInitOptions) {
    if (typeof options !== 'object') {
      throw Error(`Expected init options of type object, got: ${typeof options}`);
    }
    if (!options.clientId) {
      throw Error(`Expected non empty init options.clientId of type string, got: ${options.clientId}`);
    }
    if (!options.clientSecret) {
      throw Error(`Expected non empty init options.clientSecret of type string, got: ${options.clientSecret}`);
    }
    if (typeof options.isProduction !== 'boolean') {
      throw Error(`Expected non empty init options.isProduction of type boolean, got: ${options.isProduction}`);
    }
    const { clientId, clientSecret, isProduction } = options;
    debug('SpidClient(options: %o)', options);
    this.clientId = clientId;
    this.clientSecret = clientSecret;
    this.isProduction = isProduction;
  }

  public async spidAccessToken(): Promise<ISpidAccessToken> {
    const { clientId, clientSecret } = this;

    const options: OptionsWithUri = {
      uri: oAuthUri(this.isProduction),
      method: 'POST',
      form: {
        grant_type: `client_credentials`,
        scope: `openid profile`,
      },
      headers: {
        Authorization: `Basic ${btoa(`${clientId}:${clientSecret}`)}`,
        'Content-Type': `application/x-www-form-urlencoded`,
      },
    };
    try {
      const response = await rp(options);
      return JSON.parse(response);
    } catch (e) {
      debug(`rp(options) => error: `, e);
      return Promise.reject(typeof e === 'string' ? Error(e) : e);
    }
  }

  public async products(accessToken: string, userId: number): Promise<ISpidApiResponseProducts> {
    const options: OptionsWithUri = {
      uri: `${apiUri(this.isProduction)}/user/${userId}/products.json?oauth_token=${accessToken}&filters=active`,
    };
    try {
      const response = await rp(options);
      debug(`rp(%o) => response: `, options, response);
      return JSON.parse(response);
    } catch (e) {
      debug(`rp(%o) => error: `, options, e);
      return Promise.reject(typeof e === 'string' ? Error(e) : e);
    }
  }

  /**
   * !NB: This is no longer using the hasProduct call from SPID. It is now using the accessService.
   */
  public async product(accessToken: string, productId: string): Promise<{ entitled: any; productId: string}> {
    const options: OptionsWithUri = {
      uri: `${accessServiceApiUrl(this.isProduction)}/v1/access?id-jwt=${accessToken}&pids=${productId}`,
    };
    try {
      const response = await rp(options);
      debug(`rp(%o) => response: `, options, response);
      if (response) {
        const parsedResponse = JSON.parse(response);
        debug(`parsedResponse: `, parsedResponse)
        return {
          productId: `${productId}`,
          entitled: parsedResponse.entitled
        }
      }
      return response && JSON.parse(response).status || { productId: `${productId}`, result: false };
    } catch (e) {
      debug(`rp(%o) => error: `, options, e);
      return Promise.reject(typeof e === 'string' ? Error(e) : e);
    }
  }

  public async checkToken(req): Promise<IHasSessionSuccessResponse> {
    const spidSig = req.headers['x-snolm-spid-sig'];

    if (!spidSig) {
      return Promise.reject({} as IHasSessionSuccessResponse);
    }

    const clientShortHandName = clientShortHandNameFromHostname(req.hostname);
    const signSecret = getSignSecret(process.env, clientShortHandName);

    if (!signSecret) {
      const message = `Could not find a signature secret for client ${clientShortHandName}`;
      debug(message + ' (process.env: %o)', process.env);
      return Promise.reject({} as IHasSessionSuccessResponse);
    }
    try {
      return await verifyToken(Array.isArray(spidSig) ? spidSig[0] : spidSig, signSecret);
    } catch (error) {
      debug('verifyToken failed: %o', error);
      return Promise.reject({} as IHasSessionSuccessResponse);
    }
  };

}
