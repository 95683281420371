import * as React from 'react';
import {useEffect, useState} from 'react';
import {
  AlignItems,
  BackgroundColor,
  BorderColor,
  BorderWidth,
  ButtonSkin,
  Card,
  classNames,
  Container,
  ContainerWidth,
  Cursor,
  Display,
  FlexDirection,
  FlexWrap,
  FontFamily,
  FontSize,
  FontWeight,
  Heading,
  HeadingLevel,
  Height,
  IWithResponsiveProps,
  JustifyContent,
  LineHeight,
  Margin,
  MaxWidth,
  Padding,
  Position,
  TextAlign,
  TextColor,
  TextDecoration,
  TransparentSkin,
  Width,
  withResponsive,
  ZIndex,
} from '@snoam/pinata';
import {IWithAdminContext, withAdminContext} from '../../context/withAdminContext';

import {CapiService} from '../../services/CapiService';
import {IArticle} from '../../models/ArticleModel';
import {ProjectIconSize, ProjectIconTypes} from "../Icon/ProjectIconTypes";
import ProjectIcon from "../Icon/ProjectIcon";
import {IWithRouterContext, withRouterContext} from '../../context/withRouterContext';
import BecomeMember from '../BecomeMember/BecomeMember';
import {AdminArticle, IAdminArticle} from '../Article/AdminArticle';
import {IWithSpidContext, withSpidContext} from "@snoam/mono-spid";
import ArticleCard from "../ArticleCard/ArticleCard";
import notLoggedInArticleTeaser from "../../config/AP/not-logged-in-teaser.json";

const styleClass = {
  root: classNames(
    FontFamily.FONT_TITLE_1,
    'snoam--hero-section',
    Display.FLEX,
    FlexDirection.FLEX_COL,
    TextAlign.TEXT_CENTER,
    Position.RELATIVE,
  ),
  container: (smallScreen: boolean) => classNames(
    !smallScreen ? 'snoam--hero-container' : Margin.MB_8
  ),

  loggedInContainer: (smallScreen: boolean) => classNames(
    !smallScreen ? 'snoam--hero-container-logged-in' : Margin.MB_8,
    Padding.MD_PR_0,
    Padding.MD_PL_12,
  ),
  headingContainer: (small: boolean) => classNames(
    small ? 'snoam--hero-title-small' : 'snoam--hero-title-md',
    Display.FLEX,
    FlexDirection.FLEX_ROW,
    FlexWrap.FLEX_WRAP,
  ),
  headingText: classNames(
    TextColor.TEXT_WHITE,
    FontSize.MD_TEXT_6XL,
    Margin.MD_MT_6,
    Padding.PX_3,
    Margin.MT_0,
    TextAlign.TEXT_LEFT,
    LineHeight.LEADING_TIGHT,
    FontWeight.FONT_LIGHT,
  ),
  headingTextSub: classNames(
    TextAlign.TEXT_LEFT,
    TextColor.TEXT_WHITE,
    FontSize.MD_TEXT_4XL,
    FontSize.TEXT_2XL,
    Margin.MD_MT_6,
    Padding.PX_2,
    Margin.MT_1
  ),
  subHeaderContainer: classNames(
    TextColor.TEXT_WHITE,
    TextAlign.TEXT_LEFT,
    FontWeight.FONT_NORMAL,
    FontFamily.FONT_TITLE_2,
    Margin.MB_6,
    Padding.PX_2,
    MaxWidth.MD_MAX_W_2XL,
    MaxWidth.MAX_W_FULL,
    Width.W_FULL,
    Display.FLEX,
    AlignItems.ITEMS_START,
    JustifyContent.JUSTIFY_CENTER,
    FlexDirection.FLEX_COL,
  ),
  bulletHeding: classNames(
    TextColor.TEXT_WHITE,
    TextAlign.TEXT_LEFT
  ),
  greeting: classNames(
    FontFamily.FONT_TITLE_1,
    FontSize.MD_TEXT_6XL,
    Width.W_FULL,
  ),
  heading: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    AlignItems.ITEMS_CENTER,
    FontFamily.FONT_TITLE_1,
    FontSize.MD_TEXT_MEGA,
    FontSize.TEXT_3XL,
    TextAlign.TEXT_CENTER,
    TextColor.TEXT_NEUTRAL_8,
    FontWeight.FONT_LIGHT,
    Padding.PT_8,
    Padding.PB_12,
    Margin.MB_4,
  ),
  featureSection: {
    root: (smallScreen: boolean) => classNames(
      BackgroundColor.BG_PRIMARY_1,
      Height.H_4,
    ),
    container: (bgColor: BackgroundColor) => classNames(
      bgColor,
      TextColor.TEXT_NEUTRAL_8,
      BorderColor.BORDER_PRIMARY_2,
      BorderWidth.BORDER_B_1,
      Display.FLEX,
      MaxWidth.MAX_W_FULL,
      FlexDirection.MD_FLEX_ROW,
      FlexWrap.MD_FLEX_WRAP,
      JustifyContent.MD_JUSTIFY_CENTER,
      FlexDirection.FLEX_COL
    ),
    card: (smallScreen: boolean) => classNames(
      !smallScreen ? Margin.MX_2 : Margin.MB_4,
      Margin.MD_MR_4,
      Margin.LG_MR_2
    )
  }
};

interface IHeroProps extends IWithSpidContext, IWithResponsiveProps, IWithAdminContext, IWithRouterContext {
}

const LoggedInSection = ({spidContext, adminContext, smallScreen}) => {
  const name = spidContext.state.givenName;

  const adminArticleLoggedin = adminContext.filters.getLoggedInAdminArticle();
  if (adminArticleLoggedin === undefined) {
    return <div>Mangler deskbart innhold</div>
  }

  return (
    <>
      <Container width={ContainerWidth.WIDER} className={styleClass.loggedInContainer(smallScreen)}>
        <div className={classNames(styleClass.headingContainer(smallScreen), Padding.PX_2)}>
          <Heading level={HeadingLevel.ONE} className={classNames(styleClass.headingText, styleClass.greeting)}>
            {adminContext.filters.setGivenName(adminArticleLoggedin.title.value, name || '')}
          </Heading>
          <div className={styleClass.subHeaderContainer}>
            <AdminArticle article={adminArticleLoggedin}/>
          </div>
        </div>
      </Container>
      <div className={styleClass.featureSection.root(smallScreen)}/>
    </>
  )
};

const NotLoggedInSection = ({spidContext, adminContext, smallScreen, routerContext, articles}) => {
  const {state: {userId}} = spidContext;
  const hero = adminContext.state.default.hero.notLoggedIn;
  const adminArticleNotLoggedin = adminContext.filters.getLoggedOutAdminArticle();
  if (adminArticleNotLoggedin === undefined) {
    return <div>Mangler deskbart innhold</div>
  }
  return (
    <>
      <Container
        width={ContainerWidth.WIDE}
        className={classNames(styleClass.container(smallScreen), Padding.MD_PX_12, Padding.PX_0, Margin.MX_AUTO)}

      >
        {/* This section is fetched from CAPI.*/}
        <div className={styleClass.headingContainer(smallScreen)}>
          <div className={classNames(Display.FLEX, AlignItems.ITEMS_START, FlexDirection.FLEX_COL)}>
            <Heading level={HeadingLevel.ONE} className={styleClass.headingText}>
              {adminArticleNotLoggedin.title.value}
            </Heading>

            <AdminArticle
              article={adminArticleNotLoggedin}
              className={classNames(TextAlign.TEXT_LEFT, TextColor.TEXT_WHITE, Padding.PX_3, MaxWidth.MAX_W_5XL)}
            />

            {/* This section is fetched from CAPI.*/}
            <div className={classNames(styleClass.subHeaderContainer)}>
                <BecomeMember/>
              {!userId && <p>
                  {hero.bylineOne} <span
                    className={classNames(TextDecoration.UNDERLINE, Cursor.CURSOR_POINTER)}
                    onClick={() => spidContext.actions.login({simplified: false})}>Logg inn her.</span>
                </p>}
            </div>
          </div>
        </div>

      </Container>
      <div className={styleClass.featureSection.root(smallScreen)}/>
      <Container
        width={ContainerWidth.WIDER}
        className={styleClass.featureSection.container(BackgroundColor.BG_NEUTRAL_2)}
      >
        <div className={classNames(
          Margin.MX_AUTO,
          MaxWidth.MAX_W_2XL,
          Margin.MD_MY_12,
          Padding.MD_PY_12,
          Padding.PY_8,
        )}>
          <div className={classNames(Padding.PB_12)}>
            <AdminArticle article={notLoggedInArticleTeaser as IAdminArticle}/>
          </div>

          <BecomeMember skin={ButtonSkin.PRIMARY} text={TextColor.TEXT_WHITE}/>
        </div>
      </Container>
      <Container
        key={`free-articles-container-${articles.length}`}
        width={ContainerWidth.WIDER}
        className={styleClass.featureSection.container(BackgroundColor.BG_WHITE)}
      >
        <div className={classNames(Margin.MD_MY_12, Padding.MD_PY_12)}>
          <Heading
            level={HeadingLevel.THREE}
            className={styleClass.heading}
          >
            <ProjectIcon
              name={ProjectIconTypes.GRAPES_SIMPLE}
              size={ProjectIconSize.L}
              className={Margin.MB_12}
            />
            Artikler og anbefalinger
          </Heading>

          <div
            className={classNames(
              AlignItems.ITEMS_CENTER,
              Display.FLEX,
              FlexDirection.MD_FLEX_ROW,
              FlexDirection.FLEX_COL,
              Margin.MX_AUTO,
              Padding.PT_10,
            )}
          >
            {articles.map((article, key: number) => (
              <div key={`free-article-${key}`} className={classNames(Margin.MX_3)}>
                <ArticleCard article={article}/>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </>
  )
};

const bgImage = ({hero, userId, hasProduct}) => {
  if (userId && hasProduct) {
    return hero.loggedIn.bgImage === "" ? "/images/hero-default-bg.png" : hero.loggedIn.bgImage;
  }
  return hero.notLoggedIn.bgImage === "" ? "/images/hero-default-bg.png" : hero.notLoggedIn.bgImage;
};

const Hero: React.FC<IHeroProps> = (props) => {

  const {
    routerContext,
    adminContext,
    spidContext,
    tablet,
    mobile,
  } = props;

  const smallScreen = !!(tablet || mobile);
  const {userId} = spidContext.state;
  const {hasProduct} = adminContext.state;
  const {hero} = adminContext.state.default;

  const [articles, setArticles] = useState<IArticle[]>([]);
  useEffect(() => {
    const service = new CapiService({origin: routerContext.location.origin || ''});
    service.fetchFreeArticles()
      .then(setArticles)
  }, []);


  return (
    <div
      className={styleClass.root}
      style={{backgroundImage: `url("${bgImage({hero, userId, hasProduct})}")`}}
    >
      <Card
        transparent={TransparentSkin.PRIMARY_DARK_60}
        className={classNames(
          ZIndex.Z_10,
          Width.W_FULL,
          Height.H_FULL,
          Position.ABSOLUTE,
          Position.LEFT_0,
          Position.TOP_0,
        )}
      />
      <div className={ZIndex.Z_20}>
        {userId && hasProduct ?
          <LoggedInSection
            spidContext={spidContext}
            adminContext={adminContext}
            smallScreen={smallScreen}
          />
          :
          <NotLoggedInSection
            articles={articles}
            spidContext={spidContext}
            adminContext={adminContext}
            routerContext={routerContext}
            smallScreen={smallScreen}
          />
        }
      </div>
    </div>
  );
}


export default withSpidContext(
  withResponsive(
    withAdminContext(
      withRouterContext(
        Hero
      )
    )
  )
);
