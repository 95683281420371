import * as React from 'react';
import {
  AlignItems,
  BackgroundColor,
  classNames,
  Container,
  ContainerWidth,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  Margin,
  Padding,
  TextAlign,
  TextColor,
  Width
} from '@snoam/pinata';

const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    BackgroundColor.BG_PRIMARY_4,
    Padding.PY_12,
  ),
  container: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    JustifyContent.JUSTIFY_CENTER,
    AlignItems.ITEMS_CENTER,
    Padding.PB_12,
    Margin.MY_12,
    FontFamily.FONT_TITLE_2,
    TextAlign.TEXT_CENTER,
    TextColor.TEXT_WHITE,
    FontWeight.FONT_NORMAL,
    FontSize.TEXT_BASE,
  ),
  logo: classNames(
    Margin.MB_4,
    Width.W_56,
  ),
  paragraphMargin: classNames(
    Margin.MB_3
  ),
  paragraphbold: classNames(
    FontWeight.FONT_SEMIBOLD,
    TextColor.TEXT_WHITE
  ),
  textContainer: classNames(
    Width.W_FULL,
    Width.MD_W_3_4,
    FontSize.TEXT_BASE,
  )
}

const FooterBottom: React.FC = () => {

  return (
    <div className={styleClass.root}>
      <Container width={ContainerWidth.WIDE} className={styleClass.container}>
        <img src={'/images/a-vinklubben-logo-white.svg'} className={styleClass.logo} alt={"Vinklubb logo"}/>

        <div className={classNames(Margin.MB_6, FontWeight.FONT_MEDIUM)}>
          <p>ADRESSE</p>
          <p className={styleClass.paragraphMargin}>Akersgata 55, 0180 OSLO</p>
          <p>SJEFREDAKTØR</p>
          <p>Lillian Vambheim</p>
        </div>
        <div className={styleClass.textContainer}>
          <p className={Padding.PX_4}>Alt innhold er
            <a href="http://www.aftenposten.no/norge/GENERELLE-VILKAR-276809b.html"
               className={styleClass.paragraphbold}> opphavsrettslig beskyttet</a>. © Aftenposten.
            <a href={'http://the-acap.org/acap-enabled.php'} className={styleClass.paragraphbold}> ACAP-beskyttet.</a>
            <a href={'https://info.privacy.schibsted.com/no/schibsted-norge-personvernerklaering/'}
               className={styleClass.paragraphbold}> Personvernpolicy og cookies</a>.
            Aftenposten arbeider etter
            <a href={'http://presse.no/Etisk-regelverk/Vaer-Varsom-plakaten'} className={styleClass.paragraphbold}> Vær
              Varsom-plakatens </a>
            regler for god presseskikk. Aftenposten har ikke ansvar for innhold på eksterne nettsider som det lenkes
            til.</p>
        </div>
      </Container>
    </div>
  )
};

export default FooterBottom;
