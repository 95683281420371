import React from 'react';
import { IAdminContext, AdminConsumer } from './AdminContextProvider';
import { Subtract } from 'utility-types';

export interface IWithAdminContext {
  adminContext: IAdminContext;
}

export const withAdminContext = <P extends IWithAdminContext>(Component: React.ComponentType<P>) => 
  class WithAdminContext extends React.PureComponent<Subtract<P, IWithAdminContext>> {
    public render() {
      return (
        <AdminConsumer>
          {
            (adminContext: IAdminContext) => <Component 
              {...this.props}
              adminContext={adminContext}
            />
          }
        </AdminConsumer>
      )
    }
  }