import * as React from 'react';
import {
  BackgroundColor,
  BorderColor,
  BorderRadius,
  BorderWidth,
  classNames,
  FontFamily,
  FontSize,
  FontWeight,
  Height,
  LineHeight,
  Margin,
  MaxWidth,
  MinHeight,
  MinWidth,
  Overflow,
  Padding,
  Position,
  TextAlign,
  TextColor, TextTransform,
  Width
} from '@snoam/pinata';
import {getQueryParams} from '../../tracking/Pulse';

export interface IExpertProps {
  name: string,
  bio: string,
  imgUrl: string,
  imgAltText: string,
  className?: string,
  pageUrl: string,
}

const styleClass = {
  bio: classNames(
    FontSize.TEXT_BASE,
    Width.W_64,
    Margin.MX_AUTO,
    LineHeight.LEADING_NORMAL,
  ),
  container: (className) => classNames(
    className,
    MaxWidth.MAX_W_FULL,
    Position.RELATIVE,
    TextColor.TEXT_NEUTRAL_8,
  ),
  image: classNames(
    MinWidth.MIN_W_FULL,
    MinHeight.MIN_H_FULL,
    BorderRadius.ROUNDED_FULL,
  ),
  name: classNames(
    TextTransform.UPPERCASE,
    Width.W_FULL,
    Margin.MX_AUTO,
    Margin.MB_2,
  ),
  textContainer: classNames(
    'wineclub--expert--text',

    FontFamily.FONT_TITLE_2,
    Padding.P_4,
    TextAlign.TEXT_CENTER,
    Width.W_FULL,
    Height.H_32,
  )
};

const Expert: React.FC<IExpertProps> = ({name, bio, imgUrl, imgAltText, className, pageUrl}) => {

  return (
    <li className={styleClass.container(className)}>
      <img
        className={styleClass.image}
        src={imgUrl}
        alt={imgAltText}
        style={{width: '265px', height: 'auto'}}
      />
      <div className={styleClass.textContainer}>
        <h3 className={styleClass.name}>{name}</h3>
        <p className={styleClass.bio}>{bio}</p>
      </div>
    </li>
  );
}

export default Expert;
