import * as React from 'react';
import {
  AlignItems,
  BackgroundColor,
  classNames,
  Container,
  ContainerWidth,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  Heading,
  HeadingLevel,
  JustifyContent, LineHeight,
  Margin,
  Padding,
  TextAlign,
  TextColor,
} from '@snoam/pinata';
import ProjectIcon from '../Icon/ProjectIcon';
import {ProjectIconColor, ProjectIconSize, ProjectIconTypes} from '../Icon/ProjectIconTypes';

const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    BackgroundColor.BG_PRIMARY_1,
    Padding.PB_12,
  ),
  container: classNames(
    Display.FLEX,
    FlexDirection.MD_FLEX_ROW,
    FlexDirection.FLEX_COL,
    JustifyContent.JUSTIFY_BETWEEN,
    AlignItems.ITEMS_CENTER,
    Padding.PY_12,
    Margin.MB_12,
    FontFamily.FONT_TITLE_2,
    FontSize.TEXT_BASE,
    TextAlign.TEXT_CENTER,
    TextColor.TEXT_WHITE,
    FontWeight.FONT_THIN
  ),
  block: classNames(
    Padding.PX_6,
  ),
  textBlock: classNames(
    FontWeight.FONT_NORMAL,
    Display.FLEX,
    FlexDirection.FLEX_COL,
    Padding.PT_2
  ),
  icon: classNames(

  )
}

const FooterTop = () => {

  return (
    <div className={styleClass.root}>
      <div className={classNames(Display.FLEX, FlexDirection.FLEX_COL, AlignItems.ITEMS_CENTER, TextColor.TEXT_WHITE)}>
        <Heading
          level={HeadingLevel.THREE}
          className={classNames(
            FontFamily.FONT_TITLE_1,
            TextAlign.TEXT_CENTER,
            LineHeight.LEADING_HEADLINE1,
            FontSize.MD_TEXT_MEGA,
            FontSize.TEXT_4XL,
            Margin.MT_12,
            Padding.PT_12,
          )}
        >
          Aftenpostens vinklubb
        </Heading>
        <p className={classNames(FontFamily.FONT_TITLE_2, Padding.PT_8, TextAlign.TEXT_CENTER)}>Norges største vinklubb med over 5000 medlemmer</p>
      </div>
      <Container width={ContainerWidth.WIDE} className={styleClass.container}>
        <div className={styleClass.block}>
          <ProjectIcon
            name={ProjectIconTypes.BENEFITS}
            color={ProjectIconColor.WHITE}
            size={ProjectIconSize.L}
          />
          <div className={styleClass.textBlock}>
            <p>Vinklubbens egne</p>
            <p>A-kort fordeler</p>
          </div>
        </div>
        <div className={styleClass.block}>
          <ProjectIcon
            name={ProjectIconTypes.BOTTLES}
            color={ProjectIconColor.WHITE}
            size={ProjectIconSize.L}
          />
          <div className={styleClass.textBlock}>
            <p>Eksklusive vintips</p>
          </div>
        </div>
        <div className={styleClass.block}>
          <ProjectIcon
            name={ProjectIconTypes.WINE_KEG}
            color={ProjectIconColor.WHITE}
            size={ProjectIconSize.L}
          />
          <div className={styleClass.textBlock}>
            <p>Tilgang til vinarrangementer</p>
            <p>og vinreiser</p>
          </div>
        </div>
        <div className={styleClass.block}>
          <ProjectIcon
            name={ProjectIconTypes.BOOK}
            color={ProjectIconColor.WHITE}
            size={ProjectIconSize.L}
          />
          <div className={styleClass.textBlock}>
            <p>Nyhetsbrev tilpasset deg og</p>
            <p>dine interesser</p>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default FooterTop;
