import toPairs from "lodash/toPairs";

export interface IArticle {
  schemaVersion: 4;
  schemaType: 'article';
  id: string;
  changes: {
    created: string;
    updated: string;
    firstPublished: string;
    published: string;
  };
  section: {
    id: string;
    title: string;
    enabled: boolean;
  };
  story?: {
    id: string;
    title: string;
    updated: string;
  };
  title: {
    value: string;
  };
  authors: IComponentAuthor[];
  components: Component[];
  promotionContent: IPromotionContent;
  tags: Array<{id: string, title: string, type: string}>,
  state: string;
  newsroom: string;
}

export interface IComponentAuthor {
  id: string;
  title: string;
  description: string;
  contacts: Array<{
    type: 'email';
    value: string;
  }>;
}

export interface IPromotionContent {
  title: {
    value: string;
  };
  description: {
    value: string;
  };
  imageAsset: {
    id: string;
    size: {
      width: number;
      height: number;
    }
  };
}

export interface ILengthAndOffset {
  offset: number;
  length: number;
}

export interface IStyleMarkup extends ILengthAndOffset {
  type: 'style:em' | 'style:strong';
}

export interface ILinkExternalMarkup extends ILengthAndOffset {
  type: 'link:external';
  uri: string;
}


export type Markup =
  | IStyleMarkup
  | ILinkExternalMarkup
  ;

export interface IWithMarkup {
  markup?: Markup[];
}

export enum ComponentType {
  deprecatedIntegration = 'deprecated-integration',
  image = 'image',
  list = 'list',
  text = 'text',
}

export interface IComponent<T extends ComponentType> {
  type: T;
}

export interface IComponentImage extends IComponent<ComponentType.image> {
  caption?: {
    value: string;
  };
  byline?: {
    title: string;
  };
  imageAsset: {
    id: string;
    size: {
      width: number;
      height: number;
    }
  };
  characteristics: {
    figure: boolean;
    sensitive: boolean;
  };
  sourceId: string;
}

export interface IComponentText extends IComponent<ComponentType.text> {
  text: {
    value: string;
    markup?: Markup[];
  };
  subtype?: 'lead' | 'heading';
  styleClasses?: Record<string, any>;
}

export interface IComponentList extends IComponent<ComponentType.list> {
  subtype: 'ordered' | 'unordered';
  items: Array<IWithMarkup & { value: string }>;
}

export interface IComponentDeprecatedIntegration extends IComponent<ComponentType.deprecatedIntegration> {
  url: string;
}

export type Component =
  | IComponentDeprecatedIntegration
  | IComponentImage
  | IComponentList
  | IComponentText
  ;

export default class Article implements IArticle {
  private store: Map<string, any>;

  constructor(data) {
    this.store = new Map(toPairs(data))
  }

  get schemaVersion() {
    return this.store.get("schemaVersion")
  }

  get schemaType() {
    return this.store.get("schemaType")
  }

  get id() {
    return this.store.get("id");
  }

  get changes() {
    return this.store.get("changes");
  }

  get section() {
    return this.store.get("section");
  }

  get story() {
    return this.store.get("story");
  }

  get title() {
    return this.store.get("title");
  }

  get authors() {
    return this.store.get("authors");
  }

  get components() {
    return this.store.get("components");
  }

  get promotionContent() {
    return this.store.get("promotionContent");
  }

  get state() {
    return this.store.get("state");
  }

  get newsroom() {
    return this.store.get("newsroom");
  }

  get tags() {
    return this.store.get("tags");
  }

}
