import * as React from 'react';
import {Link} from "@snoam/pinata";

export interface IProps {
  uri: string;
}

export class ArticleExternalLink extends React.Component<IProps, {}> {

  render() {
    const {
      children,
      uri,
    } = this.props;

    return (
      <Link href={uri} target={'_blank'}>{children}</Link>
    );
  }

}

export default ArticleExternalLink;
