import {
  classNames, FlexDirection,
} from '@snoam/pinata';
import * as React from 'react';
import FooterBottom from './FooterBottom';
import { Display } from '@snoam/pinata';
import FooterTop from './FooterTop';

const styleClass = {
  container: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL
  )
};

class FooterContainer extends React.Component {
  public render() {
    return (
      <footer className={styleClass.container}>
        <FooterTop />
        <FooterBottom />
      </footer>
    );
  }
}

export default FooterContainer;
