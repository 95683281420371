import { IArticle } from '../models/ArticleModel';
import { ClientShortHandName } from '@snoam/mono-scc';
const debug = require('debug')('vinklubb:client:CapiService');

export interface IProps {
  clientShortHandName?: ClientShortHandName;
  origin?: string;
}

export class CapiService {

  private clientShortHandName: Readonly<ClientShortHandName>;
  private origin: Readonly<string>;

  constructor(props?: IProps) {
    this.clientShortHandName = props && props.clientShortHandName ? props.clientShortHandName : ClientShortHandName.AP;
    this.origin = props && props.origin ? props.origin : '';
  }

  private checkError(res: Response): Response | any {
    if (res.status >= 200 && res.status < 300) {
      return res;
    }
    return Promise.reject(res);
  }

  public fetch<T>(url, params) {
    debug(`this.fetch('%s', %o)`, `${this.origin || ''}${url}`, params);
    return fetch(`${this.origin || ''}${url}`, params)
      .then(res => this.checkError(res))
      .then(res => res.json())
      .catch(err => { throw err });
  }

  public fetchFreeArticles(): Promise<IArticle[]> {
    const url = '/api/free/articles';
    const options = {
      headers: {
        'Accept': 'application/json',
      },
      method: 'GET',
    };

    debug('getBenefits()');
    debug('calling fetch(%s, %o)', url, options);
    return this.fetch(url, options);
  }

  public async fetchAdminArticle(): Promise<IArticle[]> {
    const url = '/api/admin';
    const options = {
      headers: {
        'Accept': 'application/json',
      },
      method: 'GET',
    };

    debug('getAdminLogedout()');
    debug('calling fetch(%s, %o)', url, options);
    return this.fetch(url, options);
  }


  public async hasProduct(): Promise<any> {
    const url = '/api/hasProduct';
    const options = {
      headers: {
        'Accept': 'application/json',
      },
      method: 'GET',
    };
    debug('hasProduct()');
    debug('calling fetch(%s, %o)', url, options);
    return this.fetch(url, options);
  }

  public async fetchArticles(
    sig: string,
    queryString: string,
    from?: number,
    size?: number,
  ): Promise<IArticle[]> {

    if (queryString) {
      const url = `/api/articles/${queryString}&from=${from ? from : 0}&size=${size ? size : 9}`;
      const options = {
        headers: {
          'Accept': 'application/json',
          'x-snolm-spid-sig': sig,
        },
        method: 'GET',
      };

      debug('fetchArticles()');
      debug('calling fetch(%s, %o)', url, options);
      return this.fetch(url, options);
    }
    return Promise.resolve([]);
  }

  public async fetchArticle(sig: string, articleId: string): Promise<IArticle> {
    try {
      const url = `/api/articles/${articleId}`;
      const options = {
        headers: {
          'Accept': 'application/json',
          'x-snolm-spid-sig': sig,
        },
        method: 'GET',
      };
      debug('fetchArticle(%s)', articleId);
      debug('calling fetch(%s, %o)', url, options);
      return this.fetch(url, options);
    } catch (e) {
      debug('error fetching article', e);
      const url = `/api/articles/${articleId}`;
      const options = {
        headers: {
          'Accept': 'application/json',
          'x-snolm-spid-sig': "no token",
        },
        method: 'GET',
      };
      debug('fetchArticle(%s)', articleId);
      debug('calling fetch(%s, %o)', url, options);
      return this.fetch(url, options);
    }
  }
}
