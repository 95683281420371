export enum ProjectIconTypes {
  GRAPES = "ikon_grapes",
  GRAPES_WINE = "ikon_grapes_wine",
  CHEES_WINE_BOTTLE = "ikon_chees_wine_bottle",
  BOOK = "ikon_book",
  REDWINE_BOTTLE_GLAS = "ikon_redwine_bottle_glas",
  CORKSCREW = "ikon_corkscrew",
  GRAPES_GLAS_REDWINE = "ikon_grapes_glas_redwine",
  TEMPRATURE = "ikon_temprature",
  SMELL_WINE = "ikon_smell_wine",
  CHEES = "ikon_chees",
  SERVING_BOWL = "ikon_serving_bowl",
  REDWINE_GLAS_ROTATED = "ikon_red_wine_glas_rotated",
  HOURGLAS = "ikon_houerglas",
  SPEACH_BUBBLE = "ikon_speach_bubble",
  TURKEY_REDWINE_GLAS = "ikon_turkey_redwine_glas",
  BOWL_REDWINE_GLAS = "ikon_bowl_redwine_glas",
  BOWL_WHITEWINE_GLAS = "ikon_bowl_whitewine_glas",
  PLANE_REDWINE_GLAS = "ikon_plane_redwine_glas",
  WHITEWINE_GLAS = "ikon_whitewine_glas",
  MEAT_REDWINE_GLAS_ROTATED = "ikon_meat_redwine_glas_rotated",
  CHAMPAGNE = "ikon_champaign",
  GRAPES_PLANE = "ikon_grapes_plane",
  WINE_BOTTLE = "ikon_wine_bottle",
  EXPERTS = "ikon_experts",
  BENEFITS = "ikon_akortet",
  BOTTLES = "ikon_bottles",
  WINE_KEG = "ikon_wine_keg",
  GRAPES_SIMPLE = "ikon_grapes_simple",
  GRAPES_LEAF = "ikon_grape_leaf"

}

export enum ProjectIconColor {
  DEFAULT = "default",
  WHITE = "white",
}

export enum ProjectIconSize {
  XSS = "2.5rem",
  XS = "3.75rem",
  M = "5rem",
  L = "6.25rem",
  XL = "8.125rem",
}
