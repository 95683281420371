import * as React from 'react';
import {IExpert, IMenu, AdminService, IDefault, ICategories} from '../services/AdminService';
import { ClientShortHandName } from '@snoam/mono-scc';
import {Component, createContext} from 'react';
import { IArticle } from '../models/ArticleModel';
import { CapiService } from '../services/CapiService';

export interface IAdminContextProps {
  clientShortHandName: ClientShortHandName;
  preloadedState?: IAdminContextState;
  sig: string
}

export interface IAdminContext {
  state: IAdminContextState;
  filters: IAdminFilters;
}

export interface IAdminContextState {
  experts: IExpert[];
  menu: IMenu[];
  default: IDefault;
  categories: ICategories[];
  adminContent: IArticle[];
  hasProduct: boolean;
  productLoaded: boolean;
}

export interface IAdminFilters {
  getLoggedOutAdminArticle: () => IArticle | undefined;
  getLoggedInAdminArticle: () => IArticle | undefined;
  setGivenName: (value: string, givenName: string) => string;
}

const AdminContext = createContext<IAdminContext>({
  state: {} as IAdminContextState,
  filters: {} as IAdminFilters
});

export const AdminConsumer = AdminContext.Consumer;

export class AdminProvider extends Component<IAdminContextProps, IAdminContextState> {

  static displayName = 'AdminProvider';

  public static load(clientShortHandName: ClientShortHandName): Promise<IAdminContextState> {
    const service = new AdminService(clientShortHandName);
    return Promise.all([
      service.fetchExperts(),
      service.fetchMenu(),
      service.fetchDefault(),
      service.fetchCategories(),
    ]).then(([experts, menu, defaultConfig, categories]) => ({ experts, menu, default: defaultConfig, categories, adminContent: [], hasProduct: false, productLoaded: false}));
  }

  private service: AdminService;
  private capiService: CapiService;

  constructor(props: IAdminContextProps) {
    super(props);
    this.service = new AdminService(props.clientShortHandName);
    this.capiService = new CapiService();
    this.state = props.preloadedState || {
      experts: [],
      menu: [],
      categories: [],
      default: {
        hero: {
          notLoggedIn: {
            bgImage: "",
            titleOne: "",
            titleTwo: "",
            bulletList: [],
            bylineOne: "",
          },
          loggedIn: {
            bgImage: "",
            titleOne: "",
            titleTwo: "",
            bylineOne: "",
            bylineTwo: ""
          }
        },
        benefitsLink: "",
        becomeMemberLink: "",
        becomeMemberText: "",
        homepageLink: ""
      },
      hasProduct: false,
      productLoaded: false,
      adminContent: []
    };
  }

  componentDidUpdate(prevProps: IAdminContextProps) {
    if(prevProps !== this.props) {
      this.reload();
    }
  }

  componentDidMount() {
    if (!this.props.preloadedState) {
      this.reload()
    }
  }


  private reload = () => {
    if(this.state.experts.length === 0) {
      this.service.fetchExperts()
        .then(experts => {
          this.setState({experts});
        })
    }
    if(this.state.menu.length === 0) {
      this.service.fetchMenu()
        .then(menu => {
          this.setState({menu});
        })
    }
    this.service.fetchDefault()
      .then(defaultConfig => this.setState({default: defaultConfig}) );

    this.service.fetchCategories()
      .then(categories => this.setState({categories}));
    this.capiService.fetchAdminArticle()
      .then(adminContent => {
        this.setBecomeMemberFromAdmin(adminContent);
        this.setState({adminContent});
      });
    this.capiService.hasProduct().then(result => {
      this.setState({
        hasProduct: result.entitled,
        productLoaded: true
      })
    })
  };

  private setBecomeMemberFromAdmin = (articles: IArticle[]) => {
    const tempConfig = {...this.state.default};
    if(articles.length > 0) {
      const loggedOutArticle = articles.find(article => article.section.title === 'logged-out');
      if(loggedOutArticle) {
        if(loggedOutArticle.promotionContent && loggedOutArticle.promotionContent.description.value != "") {
          tempConfig.becomeMemberLink = loggedOutArticle.promotionContent.description.value;
        }
        if(loggedOutArticle.promotionContent && loggedOutArticle.promotionContent.title.value != "") {
          tempConfig.becomeMemberText = loggedOutArticle.promotionContent.title.value;
        }
        this.setState({default: tempConfig});
      }
    }
  };

  private getLoggedOutAdminArticle = (): IArticle | undefined => {
    const {adminContent} = this.state;
    return adminContent.filter(article => {
      if(article.section.title === "logged-out") {
        return article
      }
      return undefined;
    })[0]
  };

  private getLoggedInAdminArticle = (): IArticle | undefined => {
    const {adminContent} = this.state;
    return adminContent.filter(article => {
      if(article.section.title === "logged-in") {
        return article
      }
      return undefined;
    })[0]
  }

  private setGivenName = (value: string, givenName: string): string => {
    return value.replace("${givenName}", ` ${givenName}`);
  }

  public render() {
    const {state} = this;
    const context = {
      state,
      filters: {
        getLoggedOutAdminArticle: this.getLoggedOutAdminArticle,
        getLoggedInAdminArticle: this.getLoggedInAdminArticle,
        setGivenName: this.setGivenName
      }
    };

    return (
      <AdminContext.Provider value={context}>
        {this.props.children}
      </AdminContext.Provider>
    )
  }
}
