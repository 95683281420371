import "react-app-polyfill/ie11";
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App, { IPreloadedState } from './App';
import {BrowserRouter} from 'react-router-dom'


const debug = require('debug')('vinklubb:client');

try {
  if (!localStorage.getItem('debug')) {
    localStorage.setItem('debug', 'create-pinata-app,create-pinata-app:*,vinklubb,vinklubb:*');
  }
} catch(e) {
  // tslint:disable-next-line
  console.log('Couldn\'t enable debug, seems like localStorage is disabled...', e);
}

let preloadedState: IPreloadedState | undefined;
try {
  const json: string | undefined = (window as any).preloadedState;
  if (json) {
    preloadedState = JSON.parse(json);
  }
} catch (e) {
  debug('Unable to parse JSON: %o', e);
}

ReactDOM.render(
  <BrowserRouter>
  <App
    domain={window.location.hostname}
    href={window.location.href}
    preloadedState={preloadedState}
    location={window.location}
  /></BrowserRouter>,
  document.getElementById('root') as HTMLElement
);
