import * as React from "react";
import {useEffect} from "react";
import {CapiService} from "../../services/CapiService";
import {
  AlignItems,
  BackgroundColor,
  BorderColor,
  BorderWidth,
  Button,
  ButtonSkin,
  classNames,
  Container,
  ContainerWidth,
  Display,
  FlexDirection,
  FlexWrap, FontFamily,
  FontSize,
  FontWeight,
  Heading,
  HeadingLevel,
  IWithResponsiveProps,
  JustifyContent,
  ListStyleType,
  Margin,
  MaxWidth,
  Padding,
  TextAlign,
  TextColor,
  Width,
  withResponsive
} from "@snoam/pinata";
import ArticleCard from "../ArticleCard/ArticleCard";
import ProjectIcon from "../Icon/ProjectIcon";
import {ProjectIconSize, ProjectIconTypes} from "../Icon/ProjectIconTypes";
import {IArticle} from "../../models/ArticleModel";
import {IWithFilterContextProps, withFilterContext} from "../../context/withFilterContext";
import {getDefaultFilterId} from "../../utils";
import {RouteProps, RouterProps, withRouter} from "react-router";
import * as queryString from "query-string";
import {IWithSpidContext, useSpid, withSpidContext} from "@snoam/mono-spid";
import Loader from "../Loader/Loader";
import {ICategory} from '../../services/AdminService';
import WebStorageService from "../../shims/WebStorageService";

export interface IArticleListProps
  extends IWithSpidContext,
    IWithResponsiveProps,
    IWithFilterContextProps,
    RouterProps,
    RouteProps {
  className?: string;
  menuRef: any;
}

const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    BackgroundColor.BG_WHITE,
  ),
  header: classNames(
    Padding.PY_12,
    Display.FLEX,
    FlexDirection.FLEX_COL,
    JustifyContent.JUSTIFY_CENTER,
    TextAlign.TEXT_CENTER,
    TextColor.TEXT_PRIMARY_4,
    FontSize.TEXT_XL,
    AlignItems.ITEMS_CENTER,
    FontWeight.FONT_SEMIBOLD
  ),
  body: classNames(
    ListStyleType.LIST_NONE,
    Display.FLEX,
    FlexDirection.FLEX_ROW,
    FlexWrap.FLEX_WRAP,
    JustifyContent.JUSTIFY_AROUND,
    MaxWidth.MAX_W_6XL,
    Margin.MX_AUTO,
    Margin.MB_6,
  ),
  seperator: classNames(
    Display.INLINE_BLOCK,
    BorderWidth.BORDER_1,
    BorderColor.BORDER_PRIMARY_3,
    Width.W_32,
    Margin.MT_2,
    Margin.MB_6
  ),
  showMoreWrapper: classNames(
    Display.FLEX,
    JustifyContent.JUSTIFY_CENTER,
    AlignItems.ITEMS_CENTER,
    Padding.PY_12,
  ),
  allArticlesLoadedParagraph: classNames(
    Padding.P_3,
    BackgroundColor.BG_WHITE
  )
};
const storage = WebStorageService.getInstance();
export const ArticleList = (props) => {
  const service = new CapiService();
  const [articles, setArticles] = React.useState<IArticle[]>({} as IArticle[]);
  const [loadingBottom, setLoadingBottom] = React.useState(Boolean);
  const [loadingTop, setLoadingTop] = React.useState(Boolean);
  const [loadedAllArticles, setLoadedAllArticles] = React.useState(Boolean);
  const [size, setSize] = React.useState(9);
  const [prevMainCategory, setPrevMainCategory] = React.useState<ICategory>();
  const [prevSubCategory, setPrevSubCategory] = React.useState<ICategory>();
  const [prevFilterUri, setPrevFilterUri] = React.useState(String);

  // Fetching the "From" parameter "From" the queryParams
  // This is to keep track of which articles have already been fetched upon clicking "vis mer"
  const getFromFromQueryParam = () => {
    const {location} = props;

    if (location && location.search) {
      const queryParams = queryString.parse(location.search);
      const fromFromQuery = queryParams.from && parseInt(queryParams.from, 10);
      if (fromFromQuery) {
        return fromFromQuery;
      }
    }
    return 0;
  }

  const [from, setFrom] = React.useState(getFromFromQueryParam());
  const {filterContext} = props;
  const mainCategoryId = filterContext.mainCategory.id;

  useEffect(() => {
    fetchArticlesInitally();
  }, [])

  useEffect(() => {

    if (filterContext.filterUri !== prevFilterUri) {
      fetchArticlesInitally();
      scrollToTop();
    }

    if (
      filterContext.mainCategory !== prevMainCategory ||
      filterContext.subCategory !== prevSubCategory
    ) {
      setFrom(0);
      setSize(9);
      setLoadedAllArticles(false);
    }
    setPrevMainCategory(filterContext.mainCategory);
    setPrevSubCategory(filterContext.subCategory);
    setPrevFilterUri(filterContext.filterUri);
  }, [filterContext])

  const scrollToTop = () => {
    const {menuRef} = props;
    const element = menuRef.current;
    const elementProps = element.getBoundingClientRect();

    if (element && window && elementProps.top < 0) {
      window.scrollTo({top: element.offsetTop - 100});
    }
  };
  const spidContext = useSpid()
  const fetchArticlesInitally = () => {
    const {filterContext} = props;
    const sizeFromQuery = getSizeFromQueryParam();
    const actualSize = sizeFromQuery ? sizeFromQuery : size;

    if (from) return updateArticlesStateByLocalStorage();

    setLoadingTop(true);
    service
      .fetchArticles(spidContext.state.sig || '', filterContext.filterUri, 0, actualSize)
      .then((articles: IArticle[]) => {
        setArticles(articles);
        setLoadingTop(false);
        setFrom(articles.length);
      })
  };

  const updateArticlesStateByLocalStorage = () => {
    const articles: IArticle[] = getArticlesFromLocalStorage();
    if(articles.length > 0) {
      setArticles(articles);
      setFrom(articles.length);
    } else {
      setFrom(0)
    }
  }

  const getArticlesFromLocalStorage = (): IArticle[] => {
    return JSON.parse(storage.getItem("articles") || "[]");
  }

  const setArticlesFromLocalStorage = (articles: IArticle[]) => {
    storage.setItem("articles", JSON.stringify(articles));
  }

  const getSizeFromQueryParam = () => {
    const {location} = props;

    if (location && location.search) {
      const queryParams = queryString.parse(location.search);
      const sizeFromQuery = queryParams.size && parseInt(queryParams.size, 10);
      if (sizeFromQuery) {
        return sizeFromQuery >= 100 ? 100 : sizeFromQuery; //Limit is just to stop people fom getting all articles...
      }
    }
    return null;
  };

  const loadArticles = async () => {
    const {filterContext, history} = props;
    if (!loadingBottom) {
      setLoadingBottom(true);
      const fetchedArticles = await service.fetchArticles(
        spidContext.state.sig || '',
        filterContext.filterUri,
        from,
        size
      );

      if (fetchedArticles.length === 0) {
        setLoadedAllArticles(true)
        setLoadingBottom(false);
      }

      articles.push(...fetchedArticles);
      setArticlesFromLocalStorage(articles);
      history.replace({
        search: `?from=${from + fetchedArticles.length}&size=${size}`
      });
      setFrom(from + fetchedArticles.length)
      setArticles(articles)
      setLoadingBottom(false);
    }
  };

  return (
    <Container width={ContainerWidth.WIDER} className={styleClass.root}>
      <div className={styleClass.header}>
        <Loader isLoading={loadingTop}/>
        {mainCategoryId === getDefaultFilterId() && (
          <>
            <ProjectIcon name={ProjectIconTypes.GRAPES_LEAF} size={ProjectIconSize.L}/>
            <Heading
              level={HeadingLevel.TWO}
              className={classNames(FontFamily.FONT_TITLE_1, FontSize.TEXT_4XL, Padding.PT_12, Padding.PB_0, Padding.MD_PB_12,FontWeight.FONT_NORMAL)}>
              Vinklubben anbefaler
            </Heading>
          </>
        )}
      </div>
      <ul className={styleClass.body}>
        {articles.length > 0 && articles.map((article, key: number) => {
          return (
            <li key={key} className={classNames(Margin.MB_6, Margin.MX_3)}>
              <ArticleCard article={article}/>
            </li>
          );
        })}
      </ul>
      <div className={styleClass.showMoreWrapper}>
        {loadedAllArticles ? (
          <p className={styleClass.allArticlesLoadedParagraph}>
            Ingen artikler igjen
          </p>
        ) : (
          <>
            {loadingBottom ? (
              <Loader isLoading={loadingBottom}/>
            ) : (

              <Button
                text={"Se flere artikler"}
                className={Padding.PX_10}
                skin={ButtonSkin.PRIMARY}
                onClick={loadArticles}
              />

            )}
          </>
        )}
      </div>
    </Container>
  );
}


export default withSpidContext<any>(
  withResponsive(
    withFilterContext<any>(
      withRouter<any>(
        ArticleList
      )
    )
  )
);
