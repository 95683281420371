import *  as React from 'react';
import { IWithAdminContext, withAdminContext } from '../../context/withAdminContext';
import Expert from './Expert';
import uniqueId from "lodash/uniqueId";
import {
  AlignItems, BackgroundColor,
  classNames, Container,
  ContainerWidth,
  Display,
  FlexDirection, FontFamily, FontSize, FontWeight, Heading, HeadingLevel,
  JustifyContent,
  ListStyleType,
  Margin, Padding, TextAlign, TextColor
} from '@snoam/pinata';
import ProjectIcon from "../Icon/ProjectIcon";
import {ProjectIconSize, ProjectIconTypes} from "../Icon/ProjectIconTypes";

const styleClass = {
  experts: classNames(
    Display.FLEX,
    JustifyContent.JUSTIFY_CENTER,
    ListStyleType.LIST_NONE,
    FlexDirection.FLEX_COL,
    FlexDirection.MD_FLEX_ROW,
    AlignItems.ITEMS_CENTER,
  ),
  expertItem: classNames(
    Margin.MX_12,
    Margin.MY_4,
  ),
  heading: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    AlignItems.ITEMS_CENTER,
    FontFamily.FONT_TITLE_1,
    FontSize.MD_TEXT_MEGA,
    FontSize.TEXT_3XL,
    TextAlign.TEXT_CENTER,
    TextColor.TEXT_NEUTRAL_8,
    FontWeight.FONT_LIGHT,
    Padding.PT_8,
    Padding.PB_12,
    Margin.MB_4,
  ),
}


class Experts extends React.PureComponent<IWithAdminContext> {


  private renderExperts = () => {
    return this.props.adminContext.state.experts.map(expert => {
      return <Expert
        key={uniqueId("expert-")}
        className={styleClass.expertItem}
        pageUrl={expert.pageUrl}
        name={expert.name}
        bio={expert.bio}
        imgUrl={expert.imgUrl}
        imgAltText={expert.imgAltText}/>;
    })
  };


  public render() {
    return (
      <Container width={ContainerWidth.WIDER} className={Padding.PB_12}>
        <section className={classNames(Padding.MD_PY_12)}>
          <Heading
            level={HeadingLevel.THREE}
            className={styleClass.heading}
          >
            <ProjectIcon
              name={ProjectIconTypes.EXPERTS}
              size={ProjectIconSize.XL}
              className={Margin.MB_6}
            />

            Våre vineksperter
          </Heading>

          <ul className={styleClass.experts}>
            {this.renderExperts()}
          </ul>

        </section>
      </Container>
    )
  }
}

export default withAdminContext(Experts);
