import * as React from 'react';
import {ComponentType, IComponentList} from '../../models/ArticleModel';
import {
  classNames,
  Container,
  ContainerWidth, FontFamily, FontSize, FontWeight,
  IWithResponsiveProps, LineHeight,
  Margin, Padding,
  TextAlign, Width,
  withResponsive
} from '@snoam/pinata';
import ArticleAdminText from './ArticleAdminText';

const styleClass = {
  list: classNames(
    'pinata-list-custom',
    Width.MD_W_4_5,
    Margin.MD_MX_AUTO,
    TextAlign.TEXT_LEFT,
    FontFamily.FONT_TITLE_2,
    FontSize.TEXT_LG,
  ),
  listItem: classNames(
    Margin.MX_AUTO,
  ),
}

const ArticleAdminList: React.FC<IComponentList & IWithResponsiveProps> = (props) => {

  const {
    subtype,
    items,
    mobile,
  } = props;

  const listItems = items.map((e, i) => (
    <li key={i} className={styleClass.listItem} style={mobile ? {width: `calc(100% - 2rem)`} : {}}>
      <ArticleAdminText type={ComponentType.text} text={{...e}} styleClasses={{
        paragraph: classNames(
          FontFamily.FONT_TITLE_2,
          FontSize.MD_TEXT_LG,
          FontSize.TEXT_BASE,
          Padding.PY_1,
          LineHeight.LEADING_HEADLINE2,
        ),
        h2: classNames(
          FontFamily.FONT_TITLE_2,
          FontWeight.FONT_NORMAL,
        ),
        headingTextSub: classNames(
          FontSize.MD_TEXT_4XL,
          FontSize.TEXT_2XL,
          LineHeight.LEADING_HEADLINE1,
          Margin.MD_MT_6,
          Padding.PX_2,
          Margin.MB_6,
          Margin.MT_1
        ),
      }}/>
    </li>
  ));

  let list;
  if (subtype === 'ordered') {
    list = (
      <ol className={styleClass.list}>
        {listItems}
      </ol>
    );
  } else {
    list = (
      <ul className={styleClass.list}>
        {listItems}
      </ul>
    )
  }

  return (
    <Container width={ContainerWidth.NARROW} >
      {list}
    </Container>
  );
}

export default withResponsive(ArticleAdminList as any);
