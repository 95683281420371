import slugify from 'slugify';
import {BackgroundColor} from '@snoam/pinata';

export function buildApiUrl(hostname: string) {
  if(isTest() ) {
    if (isLocal()) {
      return 'http://localhost:3001'; //antichurn-api
    }
    return `https://test-kundeportal.${hostname.split('.')[0]}.no`;
  }
  return `https://kundeportal.${hostname}`;
}

export function isTest() {
  return isLocal() || window.location.hostname.includes('test-')
}
export function isLocal() {
  return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname.includes('.lh')
}

export function chatClientShorthandName(clientShorthandName: string) {
  const map = {
    'bti': 'bt',
    'sa': 'ab',
    'ap': 'ap',
    'fvn': 'fvn'
  };
  return map[clientShorthandName];
}

export class Deferred<T> {

  public promise: Readonly<Promise<T>>;
  public resolve: Readonly<(value?: T | PromiseLike<T>) => void> | null;
  public reject: Readonly<(reason?: any) => void> | null;

  constructor() {
    this.resolve = null;
    this.reject = null;
    this.promise = new Promise<T>(((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    }));
  }

}

export function getDefaultFilterId() {
  // Story: vinklubb-featured
  return 'e19eb599-951e-46d7-b854-907543c052e7'
}

export function slugifyIt(text: string) {
  return slugify(text, {
    replacement: '-',
    lower: true
  });
}

export const getLabelColor = (color: string) => {
  switch (color) {
    case "black":
      return BackgroundColor.BG_BLACK;
    case "red":
      return BackgroundColor.BG_NEGATIVE;
    case "green":
      return BackgroundColor.BG_POSITIVE;
    case "yellow":
      return BackgroundColor.BG_WARNING;
    case "brand":
      return BackgroundColor.BG_PRIMARY_1;
    default:
      return BackgroundColor.BG_PRIMARY_1;
  }
};

export const resolveJwtFromCookies = (
  cookies: string | string[] | undefined,
  isProd: boolean,
): string | null => {
  if (!!cookies) {
    const cookieName = isProd ? "id-jwt" : "id-jwt-pre";
    let jwts;
    if(typeof cookies === 'string') {
      jwts = cookies.split(";").filter((cookie) => cookie.includes(cookieName));
    } else {
      jwts = cookies.filter((cookie) => cookie.includes(cookieName));
    }
    if (jwts && jwts.length) {
      return jwts
        .map((jwt: string) => {
          const [key, value] = jwt.trim().split("=");
          if (key === cookieName && value) {
            return value;
          }
          return null;
        })
        .filter((n) => n)[0];
    }
  }
  return null;
};
