import * as React from 'react';
import { match as IMatch, RouteComponentProps } from 'react-router-dom'
import Article from '../Article/Article';
import Body from '../Body/Body';
import { IWithPreloadedState } from '../../App';
import { StaticRouterContext } from 'react-router';
import { NextFunction, Request, Response } from 'express';

const debug = require('debug')('vinklubb:router');

export interface IProps<T> extends RouteComponentProps<T, StaticRouterContext & IWithPreloadedState> {
  match: IMatch<T>;
}

const renderArticle = (p: IProps<{ articleId: string }>) => {
  return (
    <Article match={p.match} preloadedState={(p.staticContext || {}).preloadedState} />
  );
};

export const routes = [
  {
    path: '/artikkel/:articleId',
    component: renderArticle,
    loadData: typeof window !== 'undefined' ? undefined : async (p: { req: Request, res: Response, next: NextFunction, domain: string, match: IMatch<{ articleId: string }> }) => {
      const { articleId } = p.match.params;
      const { Capi } = require('../../server/internalRequestHandling/Capi');
      const article = await new Capi(p.req, p.res, p.next).getArticle(articleId, true);
      debug('loadData(domain: %s, match: %o): %o', p.domain, p.match, article);
      return { article };
    }
  },
  {
    path: '/kategori/:categoryId/',
    component: Body,
    exact: true
  },
  {
    path: '/kategori/:categoryId/:subCategoryId',
    component: Body,
    exact: true,
  },
  {
    path: '/',
    component: Body,
    exact: true,
  },
];
